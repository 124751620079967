<script setup lang="ts">
import type { NuxtError } from "#app";
const config = useRuntimeConfig();
const props = defineProps({
    error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
    <div class="grid l-default font-['Circular_Std']" v-auto-animate>
        <AppHeader />
        <div class="container">
            <Card>
                <h2 class="text-2xl text-navy">
                    <span class="text-navy-300" v-if="error?.statusCode"
                        >[{{ error?.statusCode }}]</span
                    >
                    Sorry, something went wrong.
                </h2>
                <p class="my-2 text-navy font-book">
                    Please try again or contact us to get help with online
                    check-in. If not, please check-in at the front desk on the
                    day of your stay.
                </p>
                <template #footer>
                    <div class="w-full flex justify-end gap-x-4">
                        <SButton
                            v-if="config.public.supportUrl"
                            :to="config.public.supportUrl"
                            target="_blank"
                            theme="danger"
                            class="text-sm"
                            >Contact us</SButton
                        >
                        <SButton class="text-sm" @click="handleError"
                            >Try again</SButton
                        >
                    </div>
                </template>
            </Card>
        </div>
        <PoweredByShackle theme="dark" />
    </div>
</template>
