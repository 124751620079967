import { defineStore } from "pinia";
import { Reservation } from "~/grpc/proto/shackle/backend/backend_pb";
import type { SessionData } from "branch-sdk";
import type { Guest } from "~/types/checkIn";
import { MP_CLAIM_ERROR } from "~/plugins/mixpanel.client";

const notUndefinedOrString = (value: any) => value !== undefined && typeof value !== "string";

const guestProcessor = (guest: Guest) => {
  const { updateFormFlags } = useCheckInStore();
  const { status, guestId } = guest;

  if (status === 'COMPLETED') {
    updateFormFlags(guestId, {
      isComplete: true,
      isInitialised: false,
      isEditing: false,
      hasError: false
    });
  }
  if ('birthDate' in guest && notUndefinedOrString(guest.birthDate)) {
    if (guest.birthDate) {
      guest.birthDate = protoDateToInputString(guest.birthDate as unknown as Date);
    }
  }

  return guest;
}

const processClaimResponse = (claimResponse: any) => {
  const clonedResponse = JSON.parse(JSON.stringify(claimResponse));
  const checkInConfiguration = clonedResponse?.checkInConfiguration;
  const listGuests = checkInConfiguration?.listGuests;
  const guests = listGuests?.guests ?? [];

  for (let guest of guests) {
    guest = guestProcessor(guest);
  }

  clonedResponse.checkInConfiguration = checkInConfiguration;

  return clonedResponse;
};

export const useWebsiteStore = defineStore("websiteStore", {
  persist: {
    paths: ["claim", "claimError", "referenceCode"],
    storage: persistedState.localStorage,
  },
  state: () => ({
    siteBackground: "",
    isFetchingClaim: false,
    referenceCode: "",
    inviteCode: "",
    claim: null as any | null,
    claimError: null as any | null,
    branchData: {} as SessionData['data_parsed'],
  }),
  getters: {
    isClaimFetched(): boolean {
      return this.claim !== null;
    },
    isClaimValid(): boolean {
      return this.isClaimFetched && ["CLAIMED"].includes(this.reservationStage);
    },
    siteBg(): string {
      return this.siteBackground;
    },
    reservationStage(): string {
      return this.reservation?.stage?.toString() ?? "STAGE_UNSPECIFIED";
    },
    reservation(): Reservation | null {
      if (this.claim === null) return null;
      return this.claim?.reservation;
    },
    property(): any {
      return this.claim?.property;
    },
    guests(): Guest[] {
      return this.claim?.checkInConfiguration?.listGuests?.guests ?? [];
    },
    guestCount(): number {
      return this.guests.length;
    },
    completedGuests(): any[] {
      return this.guests.filter((guest) => guest.status === "COMPLETED");
    },
    allGuestsCompleted(): boolean {
      return this.guests.every((guest) => guest.status === "COMPLETED");
    },
    secondaryGuests(): Guest[] {
      return this.guests.filter((guest) => guest.guestType !== "PRIMARY");
    },
    secondaryGuestIDs(): string[] {
      return this.secondaryGuests.map((guest: any) => guest.guestId);
    },
    primaryGuest(): Guest | null {
      return this.guests.find((guest: any) => guest.guestType === "PRIMARY") ?? null;
    },
    checkoutConfiguration(): any {
      return this.claim?.checkInConfiguration?.reservationCheckInPaymentConfiguration?.checkoutConfiguration ?? null;
    },
    reservationRequiresPayment(): boolean {
      return this.claim?.checkInConfiguration?.reservationCheckInPaymentConfiguration?.status === "PAYMENT_REQUIRED";
    },
    paymentAmount(): { amount: string, currency: string } {
      return this.checkoutConfiguration?.preAuthorization?.amount ?? { amount: "0", currency: "GBP" };
    },
  },
  actions: {
    updateGuest(guest: Guest) {
      const { checkInConfiguration } = this?.claim ?? {};
      const { listGuests } = checkInConfiguration ?? {};
      const { guests } = listGuests ?? [];

      const guestToUpdate = guests?.find((g: Guest) => g.guestId === guest.guestId);
      if (guestToUpdate) {
        Object.assign(guestToUpdate, guest);
      }
    },
    setSiteBackground(background = "") {
      this.siteBackground = background;
    },
    canProceedWithClaim(claim: any) {
      const reservation: Reservation = claim.reservation;
      const stage = reservation.stage.toString();
      const { updateFormFlags } = useCheckInStore();
      
      const { listGuests } = claim.checkInConfiguration ?? {};
      const { guests } = listGuests ?? [];

      const completedGuests = guests?.map(({ guestId, status }: Guest) => ({ guestId, status })).filter(({ status }: Guest) => status === 'COMPLETED');
      
      if (completedGuests?.length > 0) {
        completedGuests.forEach((guest: any) => {
          updateFormFlags(guest.guestId, {
            isComplete: true,
            isInitialised: false,
            isEditing: false,
            hasError: false
          });
        });
      }

      switch (stage) {
        case "CANCELED":
          this.setClaimError("That reservation has been canceled.")
          return false
        case "PENDING_PAYMENT":
          this.setClaimError("That reservation is waiting to process payment.")
          return false
        case "DUE_OUT":
          this.setClaimError("That reservation is ready to check out.")
          return false
        case "CHECKED_OUT":
          this.setClaimError("That reservation has been checked out.")
          return false
        default:
          this.claim = processClaimResponse(claim);
          this.setClaimError(null)
          return true
      }
    },
    setClaimError(error: any) {
      const { $mixpanel } = useNuxtApp();
      if (error !== null) {
        $mixpanel(MP_CLAIM_ERROR, { 
          error, 
          referenceCode: this.referenceCode, 
          inviteCode: this.inviteCode, 
          reservationName: this.reservation?.name
        })
      }
      this.claimError = error;
    },
    async claimByInviteCode(inviteCode: string) {
      try {
        this.isFetchingClaim = true
        const claimResponse = await useApiFetch(`/claim?inviteCode=${inviteCode}`)

        useCheckInStore().$reset();
        this.canProceedWithClaim(claimResponse)

        this.inviteCode = inviteCode

        localStorage.setItem("reservationName", claimResponse?.reservation?.name);
        localStorage.setItem("reservationStage", claimResponse?.reservation?.stage);
        if (["PENDING_CHECK_IN", "CHECKED_IN"].includes(claimResponse?.reservation?.stage.toString())) {
          navigateTo("/confirmation");
        }
      } catch (error: any) {
        this.setClaimError(error.data.message)
        throw createError(error)
      } finally {
        this.isFetchingClaim = false
      }
    },
    async claimByReferenceCode(referenceCode: string) {
      try {
        this.isFetchingClaim = true;
        const claimResponse = await useApiFetch(`/claim?referenceCode=${referenceCode}`);

        useCheckInStore().$reset();
        this.canProceedWithClaim(claimResponse);

        this.referenceCode = referenceCode;
        localStorage.setItem("reservationName", claimResponse?.reservation?.name);
        localStorage.setItem("reservationStage", claimResponse?.reservation?.stage);
        if (["PENDING_CHECK_IN", "CHECKED_IN"].includes(claimResponse?.reservation?.stage.toString())) {
          navigateTo("/confirmation");
        }
      } catch (error: any) {
        this.setClaimError("Please double check your booking reference or try again later.");
        throw createError(error);
      } finally {
        this.isFetchingClaim = false;
      }
    },
    async getListGuestsForReservation() {
      try {
        if (!this.reservation?.name) return;

        const listGuestsResponse = await useApiFetch(`/listGuests?reservationName=${this.reservation?.name}`);
        if (listGuestsResponse?.defaultFlowResponse) {

          const processedGuests = listGuestsResponse?.defaultFlowResponse?.guests?.map(guestProcessor);

          if (this.claim.checkInConfiguration) {
            this.claim.checkInConfiguration.listGuests.guests = processedGuests;
          } else {
            this.claim.checkInConfiguration = { listGuests: { guests: processedGuests } };
          }
        }
        return {
          primary: this.primaryGuest,
          secondary: this.secondaryGuests,
        };
      } catch (error: any) {
        throw createError(error);
      }
    },
    async getReservation(reservationName: string) {
      try {
        const reservationResponse = await useApiFetch(`/reservation?reservationName=${reservationName}`);
        if (this.claim) {
          this.claim.reservation = reservationResponse;
        } else {
          this.claim = { reservation: reservationResponse };
        }
      } catch (error: any) {
        throw createError(error);
      }
    },
  },
});
