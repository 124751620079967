<template>
  <component :is="buttonType" v-bind="interactive ? $attrs : {}" :disabled="disabled" :class="buttonTheme" :to="to" data-btn>
    <slot/>
  </component>
</template>

<script lang="ts" setup>
interface ButtonProps {
  theme?: 'primary' | 'secondary' | 'brand' | 'danger' | 'ghost';
  square?: boolean;
  disabled?: boolean;
  padX?: boolean;
  padY?: boolean;
  interactive?: boolean;
  to?: string;
}

const props = withDefaults(defineProps<ButtonProps>(), {
  theme: 'primary',
  padX: true,
  padY: true,
  interactive: true,
});

const buttonType = computed(() => {
  return props.to ? 'router-link' : 'button';
});

const buttonTheme = computed(() => {
  const isInteractive = () => {
    if (props.disabled) return 'cursor-not-allowed';
    return props.interactive ? 'cursor-pointer' : 'cursor-default'
  };
  const defaults = `text-center transition-colors rounded-2xl leading-snug tracking-wide ${isInteractive()}`;
  
  const disabledTheme = `bg-gray-300 text-white cursor-not-allowed ${defaults}`;
  const primaryTheme = `bg-navy hover:bg-navy-900 text-white ${defaults}`;
  const secondaryTheme = `bg-gray-200 hover:bg-gray-100 text-gray-800 ${defaults}`;
  const brandTheme = `bg-teal text-white ${defaults}`;
  const ghostTheme = `bg-transparent hover:bg-gray-100 text-navy ${defaults}`;
  const dangerTheme = `bg-alert-danger-200 hover:bg-alert-danger-100 text-alert-danger ${defaults}`;

  let propTheme = ``;

  switch (props.theme) {
    case 'brand':
      propTheme = brandTheme
      break
    case 'secondary':
      propTheme = secondaryTheme
      break
    case 'danger':
      propTheme = dangerTheme
      break
    case 'ghost':
      propTheme = ghostTheme
      break
    case 'primary':
      propTheme = primaryTheme
      break
  }

  const padX = props.padX ? `px-6` : 'px-2';
  const padY = props.padY ? `py-3` : 'py-1';
  const padding = () => {
    if (props.square) return `h-10 w-10 justify-center flex items-center mx-2 rounded-xl`
    return `${padX} ${padY}`
  };

  if (props.disabled) return `${disabledTheme} ${padding()}`;

  return `${propTheme} ${padding()}`
});
</script>