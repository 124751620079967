const ERR_MESSAGE = 'For security reasons, your session has expired. Please continue your claim using the reference code provided to you.'

const useApiFetch = async (url: string, options?: any) => {
  // test for a session on the client - if it doesnt exist reset the session
  const session = useCookie('shackleAuth.session')

  if (session.value === null || session.value === undefined) {
    const beforeReset = () => {
      useUserStore().$reset()
    }
    
    await resetClientStorage(false, () => beforeReset())
    if (import.meta.client) {
      useWebsiteStore().setClaimError(ERR_MESSAGE)
      window.location.reload() // 🙄
    }
  }

  return await $fetch(`/api${url}`, options)
}

export default useApiFetch;