import { defineNuxtPlugin } from "#app";
import { type BranchError, type SessionData } from "branch-sdk";
import { useWebsiteStore } from "#imports";
import { MP_CLAIMED_BY_INVITE } from "~/plugins/mixpanel.client";
export default defineNuxtPlugin(async (nuxtApp) => {
  if (import.meta.client) {
    const config = useRuntimeConfig();
    // Dynamically import branch-sdk only on the client side
    const branch = (await import("branch-sdk")).default;

    // Initialize Branch.io SDK
    branch.init(
      config.public.branchIoKey as string,
      (err: BranchError, data: SessionData | null) => {
        const websiteStore = useWebsiteStore()
        if (err) {
          console.error("Branch initialization error:", err);
          websiteStore.setClaimError(err)
        }
        if (data) {
          websiteStore.branchData = data.data_parsed
          if (data.data_parsed?.invitation_code) {
            const { $mixpanel } = useNuxtApp();
            $mixpanel(MP_CLAIMED_BY_INVITE, { invitation_code: data.data_parsed?.invitation_code })
            websiteStore.claimByInviteCode(data.data_parsed?.invitation_code as string)
            websiteStore.setClaimError(null)
          }
        }
      }
    );

    nuxtApp.provide("branch", branch);
  }
});
