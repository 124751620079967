// Shackle API.
//
// This API was written based on Google's API Design Guide. Standard fields such as `name`, `parent` have common
// meaning that makes them easier to use.
//
// NOTE: repeated fields are named with singular nouns. In this we oppose Google's API Design guide. This is because
//       generated code looks better, example:
//          `repeated Property properties` -> getProperties(n), getPropertiesList()
//          `repeated Property property` -> getProperty(n), getPropertyList()
//
// Recommended reading:
// [resource oriented design](https://cloud.google.com/apis/design/resources#resources)
// [why use relative resource names?](https://cloud.google.com/apis/design/resource_names#questions)
// [standard fields](https://cloud.google.com/apis/design/standard_fields)
// [design patterns](https://cloud.google.com/apis/design/design_patterns)

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file shackle/backend/backend.proto (package shackle.backend.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { DisplayColor, DisplayIcon, Failure, Money, Region } from "../common_pb.js";
import { InvoiceSection, Property } from "./common_pb.js";
import { Date } from "../../google/type/date_pb.js";

/**
 * Values for "google.rpc.ErrorInfo.reason" in the "exploreshackle.com/backend" domain.
 * Do NOT change the names of these enum values, clients depend on it.
 *
 * @generated from enum shackle.backend.v1.ErrorReason
 */
export enum ErrorReason {
  /**
   * Do not use this, fallback value.
   *
   * @generated from enum value: ERROR_REASON_UNSPECIFIED = 0;
   */
  ERROR_REASON_UNSPECIFIED = 0,

  /**
   * Reservation is already linked to a user other than the one attempting to claim it.
   *
   * @generated from enum value: RESERVATION_ALREADY_LINKED = 1;
   */
  RESERVATION_ALREADY_LINKED = 1,

  /**
   * The phone number is already registered to the same account.
   *
   * @generated from enum value: PHONE_NUMBER_ALREADY_REGISTERED_TO_SAME_ACCOUNT = 2;
   */
  PHONE_NUMBER_ALREADY_REGISTERED_TO_SAME_ACCOUNT = 2,

  /**
   * The phone number is already registered to the same account.
   *
   * @generated from enum value: PHONE_NUMBER_ALREADY_REGISTERED_TO_DIFFERENT_ACCOUNT = 3;
   */
  PHONE_NUMBER_ALREADY_REGISTERED_TO_DIFFERENT_ACCOUNT = 3,

  /**
   * Max claims reached for reservation.
   *
   * @generated from enum value: MAXIMUM_CLAIMS_REACHED = 4;
   */
  MAXIMUM_CLAIMS_REACHED = 4,

  /**
   * The reservation is not in house.
   *
   * @generated from enum value: RESERVATION_NOT_IN_HOUSE = 5;
   */
  RESERVATION_NOT_IN_HOUSE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ErrorReason)
proto3.util.setEnumType(ErrorReason, "shackle.backend.v1.ErrorReason", [
  { no: 0, name: "ERROR_REASON_UNSPECIFIED" },
  { no: 1, name: "RESERVATION_ALREADY_LINKED" },
  { no: 2, name: "PHONE_NUMBER_ALREADY_REGISTERED_TO_SAME_ACCOUNT" },
  { no: 3, name: "PHONE_NUMBER_ALREADY_REGISTERED_TO_DIFFERENT_ACCOUNT" },
  { no: 4, name: "MAXIMUM_CLAIMS_REACHED" },
  { no: 5, name: "RESERVATION_NOT_IN_HOUSE" },
]);

/**
 * @generated from enum shackle.backend.v1.Notice
 */
export enum Notice {
  /**
   * Do not use this, fallback value.
   *
   * @generated from enum value: NOTICE_UNSPECIFIED = 0;
   */
  NOTICE_UNSPECIFIED = 0,

  /**
   * Mobile key is for this specifc guest not available.
   * Guest needs to be redirected to reception to get a physical key.
   *
   * @generated from enum value: MOBILE_KEY_NOT_AVAILABLE = 1;
   */
  MOBILE_KEY_NOT_AVAILABLE = 1,

  /**
   * Check out was done from a guest perspective, hotel checkout is pending on front desk actions
   *
   * @generated from enum value: PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE = 2;
   */
  PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Notice)
proto3.util.setEnumType(Notice, "shackle.backend.v1.Notice", [
  { no: 0, name: "NOTICE_UNSPECIFIED" },
  { no: 1, name: "MOBILE_KEY_NOT_AVAILABLE" },
  { no: 2, name: "PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE" },
]);

/**
 * @generated from enum shackle.backend.v1.PaymentStatus
 */
export enum PaymentStatus {
  /**
   * Do not use this, fallback value.
   *
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Reservation is already pre-paid
   *
   * @generated from enum value: PRE_PAID_RESERVATION = 1;
   */
  PRE_PAID_RESERVATION = 1,

  /**
   * Reservation has a Pre Auth Required
   *
   * @generated from enum value: PREAUTH_REQUIRED = 2;
   */
  PREAUTH_REQUIRED = 2,

  /**
   * Reservation cannot be paid via the app
   *
   * @generated from enum value: MANUAL_PAYMENT_REQUIRED = 3;
   */
  MANUAL_PAYMENT_REQUIRED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentStatus)
proto3.util.setEnumType(PaymentStatus, "shackle.backend.v1.PaymentStatus", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "PRE_PAID_RESERVATION" },
  { no: 2, name: "PREAUTH_REQUIRED" },
  { no: 3, name: "MANUAL_PAYMENT_REQUIRED" },
]);

/**
 * @generated from enum shackle.backend.v1.TravelDocumentType
 */
export enum TravelDocumentType {
  /**
   * Do not use this, fallback value.
   *
   * @generated from enum value: OTHER = 0;
   */
  OTHER = 0,

  /**
   * Passport document type for all types of passports (Official, second, diplomatic)
   *
   * @generated from enum value: PASSPORT = 1;
   */
  PASSPORT = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(TravelDocumentType)
proto3.util.setEnumType(TravelDocumentType, "shackle.backend.v1.TravelDocumentType", [
  { no: 0, name: "OTHER" },
  { no: 1, name: "PASSPORT" },
]);

/**
 * @generated from enum shackle.backend.v1.DeviceIdStatus
 */
export enum DeviceIdStatus {
  /**
   * In case deviceId cannot be found in db or somehow status of deviceID cannot be recognized
   *
   * @generated from enum value: UNKNOWN_STATUS = 0;
   */
  UNKNOWN_STATUS = 0,

  /**
   * DeviceId is just created but not registered yet
   *
   * @generated from enum value: CREATED = 1;
   */
  CREATED = 1,

  /**
   * DeviceId is registered via legic SDK
   *
   * @generated from enum value: REGISTERED = 2;
   */
  REGISTERED = 2,

  /**
   * DeviceId is provisioned by shackle backend
   *
   * @generated from enum value: PROVISIONED = 3;
   */
  PROVISIONED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceIdStatus)
proto3.util.setEnumType(DeviceIdStatus, "shackle.backend.v1.DeviceIdStatus", [
  { no: 0, name: "UNKNOWN_STATUS" },
  { no: 1, name: "CREATED" },
  { no: 2, name: "REGISTERED" },
  { no: 3, name: "PROVISIONED" },
]);

/**
 * @generated from message shackle.backend.v1.User
 */
export class User extends Message<User> {
  /**
   * Output only. Resource name. Not to be confused with the user's natural name. Example: `users/dlakmd`.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Output only. Email from Firebase. Backend sets this field during creation and sync.
   *
   * @generated from field: string firebase_email = 2;
   */
  firebaseEmail = "";

  /**
   * Will be removed (first_name)
   *
   * @generated from field: string firebase_phone = 3 [deprecated = true];
   * @deprecated
   */
  firebasePhone = "";

  /**
   * Will be removed (first_name)
   *
   * @generated from field: string first_name = 4 [deprecated = true];
   * @deprecated
   */
  firstName = "";

  /**
   * Will be removed (last_name)
   *
   * @generated from field: string last_name = 5 [deprecated = true];
   * @deprecated
   */
  lastName = "";

  /**
   * Output only. Verified phone number.
   *
   * @generated from field: shackle.backend.v1.PhoneNumber phone_number = 6;
   */
  phoneNumber?: PhoneNumber;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firebase_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firebase_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone_number", kind: "message", T: PhoneNumber },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * For more info on E164, see: https://www.twilio.com/docs/glossary/what-e164
 * and: https://support.twilio.com/hc/en-us/articles/223183008-Formatting-International-Phone-Numbers
 *
 * @generated from message shackle.backend.v1.PhoneNumber
 */
export class PhoneNumber extends Message<PhoneNumber> {
  /**
   * Required. The E164 country code of the phone number. Example: `31`
   *
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * Required. The E164 national number of the phone number (usually without leading zero). Example: `612345678`.
   * Note on terminology, this can also be the subscriber number and will also include the extension number.
   * Basically it is just the E164 international number split up till the point of the country code.
   *
   * @generated from field: string national_number = 3;
   */
  nationalNumber = "";

  constructor(data?: PartialMessage<PhoneNumber>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.PhoneNumber";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "national_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PhoneNumber {
    return new PhoneNumber().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PhoneNumber {
    return new PhoneNumber().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PhoneNumber {
    return new PhoneNumber().fromJsonString(jsonString, options);
  }

  static equals(a: PhoneNumber | PlainMessage<PhoneNumber> | undefined, b: PhoneNumber | PlainMessage<PhoneNumber> | undefined): boolean {
    return proto3.util.equals(PhoneNumber, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetReservationMinimalRequest
 */
export class GetReservationMinimalRequest extends Message<GetReservationMinimalRequest> {
  /**
   * Required. Name of the reservation. Example: "users/12ldmik13d/reservations/1d3niofmi2".
   *
   * @generated from field: string reservation_name = 1;
   */
  reservationName = "";

  constructor(data?: PartialMessage<GetReservationMinimalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetReservationMinimalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetReservationMinimalRequest {
    return new GetReservationMinimalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetReservationMinimalRequest {
    return new GetReservationMinimalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetReservationMinimalRequest {
    return new GetReservationMinimalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetReservationMinimalRequest | PlainMessage<GetReservationMinimalRequest> | undefined, b: GetReservationMinimalRequest | PlainMessage<GetReservationMinimalRequest> | undefined): boolean {
    return proto3.util.equals(GetReservationMinimalRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetReservationMinimalResponse
 */
export class GetReservationMinimalResponse extends Message<GetReservationMinimalResponse> {
  /**
   * user reservation name "users/12ldmik13d/reservations/1d3niofmi2"
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * property name "properties/qlkjwdnl1k3jd"
   *
   * @generated from field: string property_id = 2;
   */
  propertyId = "";

  /**
   * property display name "Dummy Hotel"
   *
   * @generated from field: string property_display_name = 3;
   */
  propertyDisplayName = "";

  /**
   * arrival time
   *
   * @generated from field: google.protobuf.Timestamp arrival_time = 4;
   */
  arrivalTime?: Timestamp;

  /**
   * departure time
   *
   * @generated from field: google.protobuf.Timestamp departure_time = 5;
   */
  departureTime?: Timestamp;

  /**
   * stage of reservation
   *
   * @generated from field: shackle.backend.v1.Reservation.Stage stage = 6;
   */
  stage = Reservation_Stage.STAGE_UNSPECIFIED;

  /**
   * int guest count
   *
   * @generated from field: int32 guest_count = 7;
   */
  guestCount = 0;

  constructor(data?: PartialMessage<GetReservationMinimalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetReservationMinimalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "property_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "property_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "arrival_time", kind: "message", T: Timestamp },
    { no: 5, name: "departure_time", kind: "message", T: Timestamp },
    { no: 6, name: "stage", kind: "enum", T: proto3.getEnumType(Reservation_Stage) },
    { no: 7, name: "guest_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetReservationMinimalResponse {
    return new GetReservationMinimalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetReservationMinimalResponse {
    return new GetReservationMinimalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetReservationMinimalResponse {
    return new GetReservationMinimalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetReservationMinimalResponse | PlainMessage<GetReservationMinimalResponse> | undefined, b: GetReservationMinimalResponse | PlainMessage<GetReservationMinimalResponse> | undefined): boolean {
    return proto3.util.equals(GetReservationMinimalResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation
 */
export class Reservation extends Message<Reservation> {
  /**
   * Output only. Name of the reservation. Example: "users/12ldmik13d/reservations/1d3niofmi2".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Output only. Stage of the reservation, see enum value docs.
   *
   * @generated from field: shackle.backend.v1.Reservation.Stage stage = 2;
   */
  stage = Reservation_Stage.STAGE_UNSPECIFIED;

  /**
   * Output only. Name of the property. Ex: "properties/qlkjwdnl1k3jd".
   *
   * @generated from field: string property_name = 3;
   */
  propertyName = "";

  /**
   * Output only. Earliest time the hotel agreed to receive the guest. Sometimes the hotel may be
   * able to accommodate the guest at an earlier time than this, subject to room availability /
   * negotiated early check-in fees.
   *
   * @generated from field: google.protobuf.Timestamp earliest_check_in_time = 4;
   */
  earliestCheckInTime?: Timestamp;

  /**
   * Output only. Latest time the user can stay before checking out and losing access to the room.
   *
   * @generated from field: google.protobuf.Timestamp latest_check_out_time = 5;
   */
  latestCheckOutTime?: Timestamp;

  /**
   * Output only. Number of guests reserved for.
   *
   * @generated from field: int32 guest_count = 6;
   */
  guestCount = 0;

  /**
   * Output only. Rooms assigned to the reservation.
   *
   * @generated from field: repeated shackle.backend.v1.Reservation.Room room = 7;
   */
  room: Reservation_Room[] = [];

  /**
   * DEPRECATED. Use shackle.backend.v1.checkin.CheckIn/GetCheckInRequirements to tell if a claimed
   * reservation has accepted the terms or not.
   *
   * Output only. Agreement to Terms and Conditions.
   *
   * @generated from field: bool agreedToTermsAndConditions = 8 [deprecated = true];
   * @deprecated
   */
  agreedToTermsAndConditions = false;

  /**
   * Output only. IANA time zone of the reservation's property.
   * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   *
   * @generated from field: string property_time_zone = 9;
   */
  propertyTimeZone = "";

  /**
   * Output only. The id used by front office personnel to identify the reservation in their systems.
   *
   * @generated from field: string front_office_id = 10;
   */
  frontOfficeId = "";

  /**
   * Output only. Required
   *
   * @generated from field: shackle.common.Region region = 11;
   */
  region = Region.eu;

  /**
   * Required. Based on hotel support for mobile keys.
   *
   * @generated from oneof shackle.backend.v1.Reservation.key
   */
  key: {
    /**
     * @generated from field: shackle.backend.v1.Reservation.MobileKey mobile_key = 32;
     */
    value: Reservation_MobileKey;
    case: "mobileKey";
  } | {
    /**
     * @generated from field: shackle.backend.v1.Reservation.PhysicalKey physical_key = 33;
     */
    value: Reservation_PhysicalKey;
    case: "physicalKey";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof shackle.backend.v1.Reservation.lock
   */
  lock: {
    /**
     * @generated from field: shackle.backend.v1.Reservation.DormakabaLock dormakaba_lock = 31 [deprecated = true];
     * @deprecated
     */
    value: Reservation_DormakabaLock;
    case: "dormakabaLock";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Output only. An informational notice for the guest.
   * This differs from a failure because it doesn't block actions.
   *
   * @generated from field: repeated shackle.backend.v1.Notice notice = 50;
   */
  notice: Notice[] = [];

  /**
   * Output only. Failure state, on top of the reservation stage.
   * This will be set in the case there is an unrecovarable error and the guest needs to act on it.
   * The failure will be cleared if the reservation is move to a next stage externally.
   * Calling actions when this field is set, will return an error immediately.
   *
   * @generated from field: shackle.common.Failure failure = 51;
   */
  failure?: Failure;

  /**
   * @generated from field: shackle.backend.v1.Reservation.DisplayStage display_stage = 52;
   */
  displayStage?: Reservation_DisplayStage;

  /**
   * @generated from field: optional string share_link = 53;
   */
  shareLink?: string;

  /**
   * @generated from field: repeated shackle.backend.v1.Reservation.GuestMetadata guest_metadata = 54;
   */
  guestMetadata: Reservation_GuestMetadata[] = [];

  constructor(data?: PartialMessage<Reservation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stage", kind: "enum", T: proto3.getEnumType(Reservation_Stage) },
    { no: 3, name: "property_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "earliest_check_in_time", kind: "message", T: Timestamp },
    { no: 5, name: "latest_check_out_time", kind: "message", T: Timestamp },
    { no: 6, name: "guest_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "room", kind: "message", T: Reservation_Room, repeated: true },
    { no: 8, name: "agreedToTermsAndConditions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "property_time_zone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "front_office_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "region", kind: "enum", T: proto3.getEnumType(Region) },
    { no: 32, name: "mobile_key", kind: "message", T: Reservation_MobileKey, oneof: "key" },
    { no: 33, name: "physical_key", kind: "message", T: Reservation_PhysicalKey, oneof: "key" },
    { no: 31, name: "dormakaba_lock", kind: "message", T: Reservation_DormakabaLock, oneof: "lock" },
    { no: 50, name: "notice", kind: "enum", T: proto3.getEnumType(Notice), repeated: true },
    { no: 51, name: "failure", kind: "message", T: Failure },
    { no: 52, name: "display_stage", kind: "message", T: Reservation_DisplayStage },
    { no: 53, name: "share_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 54, name: "guest_metadata", kind: "message", T: Reservation_GuestMetadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation {
    return new Reservation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation {
    return new Reservation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation {
    return new Reservation().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation | PlainMessage<Reservation> | undefined, b: Reservation | PlainMessage<Reservation> | undefined): boolean {
    return proto3.util.equals(Reservation, a, b);
  }
}

/**
 * @generated from enum shackle.backend.v1.Reservation.Stage
 */
export enum Reservation_Stage {
  /**
   * When a new stage is added which a client doesn't know, protobuf will default to this value.
   * Use this to check whether a client must be updated.
   *
   * @generated from enum value: STAGE_UNSPECIFIED = 0;
   */
  STAGE_UNSPECIFIED = 0,

  /**
   * User claimed the reservation (either automatically, or manually by property and
   * confirmation number).
   *
   * @generated from enum value: CLAIMED = 1;
   */
  CLAIMED = 1,

  /**
   * Guest filled in the necessary registration forms, agreed to property t&c. The reservation
   * will be checked in as soon as possible.
   *
   * @generated from enum value: PENDING_CHECK_IN = 2;
   */
  PENDING_CHECK_IN = 2,

  /**
   * Clerk assigned an available room, guest received room key. Room is ready to enter.
   *
   * @generated from enum value: CHECKED_IN = 3;
   */
  CHECKED_IN = 3,

  /**
   * Guest is due out today according to business date logic of PMS. Ready to check out.
   *
   * @generated from enum value: DUE_OUT = 4;
   */
  DUE_OUT = 4,

  /**
   * Clerk zeroed-out the guest bill, guest vacated the room, the room key is null and void.
   *
   * @generated from enum value: CHECKED_OUT = 5;
   */
  CHECKED_OUT = 5,

  /**
   * Reservation was canceled or guest did not show up.
   *
   * @generated from enum value: CANCELED = 6;
   */
  CANCELED = 6,

  /**
   * The reservation payment is pending.
   *
   * @generated from enum value: PENDING_PAYMENT = 7;
   */
  PENDING_PAYMENT = 7,

  /**
   * The reservation is pending check out.
   *
   * @generated from enum value: PENDING_CHECK_OUT = 8;
   */
  PENDING_CHECK_OUT = 8,

  /**
   * Guest filled in the necessary registration forms, agreed to property t&c.
   * However manual steps are required to resume the check in.
   *
   * @generated from enum value: PENDING_CHECK_IN_VERIFICATION = 9;
   */
  PENDING_CHECK_IN_VERIFICATION = 9,

  /**
   * Guest filled in the necessary registration forms, agreed to property t&c. Backend is dealing with the request async
   *
   * @generated from enum value: PROCESSING_CHECK_IN_REQUEST = 10;
   */
  PROCESSING_CHECK_IN_REQUEST = 10,

  /**
   * ID verification process is in progress
   *
   * @generated from enum value: PENDING_ID_VERIFICATION = 11;
   */
  PENDING_ID_VERIFICATION = 11,

  /**
   * one or many guest failed to complete the ID verification process
   *
   * @generated from enum value: FAILED_ID_VERIFICATION = 12;
   */
  FAILED_ID_VERIFICATION = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(Reservation_Stage)
proto3.util.setEnumType(Reservation_Stage, "shackle.backend.v1.Reservation.Stage", [
  { no: 0, name: "STAGE_UNSPECIFIED" },
  { no: 1, name: "CLAIMED" },
  { no: 2, name: "PENDING_CHECK_IN" },
  { no: 3, name: "CHECKED_IN" },
  { no: 4, name: "DUE_OUT" },
  { no: 5, name: "CHECKED_OUT" },
  { no: 6, name: "CANCELED" },
  { no: 7, name: "PENDING_PAYMENT" },
  { no: 8, name: "PENDING_CHECK_OUT" },
  { no: 9, name: "PENDING_CHECK_IN_VERIFICATION" },
  { no: 10, name: "PROCESSING_CHECK_IN_REQUEST" },
  { no: 11, name: "PENDING_ID_VERIFICATION" },
  { no: 12, name: "FAILED_ID_VERIFICATION" },
]);

/**
 * @generated from message shackle.backend.v1.Reservation.DisplayStage
 */
export class Reservation_DisplayStage extends Message<Reservation_DisplayStage> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: shackle.common.DisplayIcon display_icon = 2;
   */
  displayIcon = DisplayIcon.UNKNOWN_DISPLAY_ICON;

  /**
   * @generated from field: shackle.common.DisplayColor display_color = 3;
   */
  displayColor = DisplayColor.UNKNOWN_DISPLAY_COLOR;

  /**
   * @generated from field: shackle.backend.v1.Reservation.DisplayStage.Description description = 4;
   */
  description?: Reservation_DisplayStage_Description;

  constructor(data?: PartialMessage<Reservation_DisplayStage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.DisplayStage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_icon", kind: "enum", T: proto3.getEnumType(DisplayIcon) },
    { no: 3, name: "display_color", kind: "enum", T: proto3.getEnumType(DisplayColor) },
    { no: 4, name: "description", kind: "message", T: Reservation_DisplayStage_Description },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_DisplayStage {
    return new Reservation_DisplayStage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_DisplayStage {
    return new Reservation_DisplayStage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_DisplayStage {
    return new Reservation_DisplayStage().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_DisplayStage | PlainMessage<Reservation_DisplayStage> | undefined, b: Reservation_DisplayStage | PlainMessage<Reservation_DisplayStage> | undefined): boolean {
    return proto3.util.equals(Reservation_DisplayStage, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.DisplayStage.Description
 */
export class Reservation_DisplayStage_Description extends Message<Reservation_DisplayStage_Description> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string body = 2;
   */
  body = "";

  /**
   * @generated from field: shackle.common.DisplayIcon display_icon = 3;
   */
  displayIcon = DisplayIcon.UNKNOWN_DISPLAY_ICON;

  constructor(data?: PartialMessage<Reservation_DisplayStage_Description>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.DisplayStage.Description";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_icon", kind: "enum", T: proto3.getEnumType(DisplayIcon) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_DisplayStage_Description {
    return new Reservation_DisplayStage_Description().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_DisplayStage_Description {
    return new Reservation_DisplayStage_Description().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_DisplayStage_Description {
    return new Reservation_DisplayStage_Description().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_DisplayStage_Description | PlainMessage<Reservation_DisplayStage_Description> | undefined, b: Reservation_DisplayStage_Description | PlainMessage<Reservation_DisplayStage_Description> | undefined): boolean {
    return proto3.util.equals(Reservation_DisplayStage_Description, a, b);
  }
}

/**
 * Room numbers are put in a message to support future extension.
 *
 * @generated from message shackle.backend.v1.Reservation.Room
 */
export class Reservation_Room extends Message<Reservation_Room> {
  /**
   * Output only. Number of the room, human readable.
   *
   * @generated from field: string number = 1;
   */
  number = "";

  /**
   * Output only. If a room number exists, a human readable description of the room is provided.
   *
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<Reservation_Room>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.Room";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_Room {
    return new Reservation_Room().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_Room {
    return new Reservation_Room().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_Room {
    return new Reservation_Room().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_Room | PlainMessage<Reservation_Room> | undefined, b: Reservation_Room | PlainMessage<Reservation_Room> | undefined): boolean {
    return proto3.util.equals(Reservation_Room, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.PhysicalKey
 */
export class Reservation_PhysicalKey extends Message<Reservation_PhysicalKey> {
  constructor(data?: PartialMessage<Reservation_PhysicalKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.PhysicalKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_PhysicalKey {
    return new Reservation_PhysicalKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_PhysicalKey {
    return new Reservation_PhysicalKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_PhysicalKey {
    return new Reservation_PhysicalKey().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_PhysicalKey | PlainMessage<Reservation_PhysicalKey> | undefined, b: Reservation_PhysicalKey | PlainMessage<Reservation_PhysicalKey> | undefined): boolean {
    return proto3.util.equals(Reservation_PhysicalKey, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.MobileKey
 */
export class Reservation_MobileKey extends Message<Reservation_MobileKey> {
  /**
   * Required.
   *
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * Required.
   *
   * @generated from oneof shackle.backend.v1.Reservation.MobileKey.lock_type
   */
  lockType: {
    /**
     * @generated from field: shackle.backend.v1.Reservation.MobileKey.Dormakaba dormakaba = 10;
     */
    value: Reservation_MobileKey_Dormakaba;
    case: "dormakaba";
  } | {
    /**
     * @generated from field: shackle.backend.v1.Reservation.MobileKey.AssaAbloy assa_abloy = 11;
     */
    value: Reservation_MobileKey_AssaAbloy;
    case: "assaAbloy";
  } | {
    /**
     * @generated from field: shackle.backend.v1.Reservation.MobileKey.Salto salto = 12;
     */
    value: Reservation_MobileKey_Salto;
    case: "salto";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Required.
   *
   * @generated from oneof shackle.backend.v1.Reservation.MobileKey.status
   */
  status: {
    /**
     * Mobile key is allowed and activated
     *
     * @generated from field: shackle.backend.v1.Reservation.MobileKey.Allowed allowed = 20;
     */
    value: Reservation_MobileKey_Allowed;
    case: "allowed";
  } | {
    /**
     * Access to mobile key has been revoked by front desk or room is not supported
     *
     * @generated from field: shackle.backend.v1.Reservation.MobileKey.Revoked revoked = 21;
     */
    value: Reservation_MobileKey_Revoked;
    case: "revoked";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Reservation_MobileKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.MobileKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "dormakaba", kind: "message", T: Reservation_MobileKey_Dormakaba, oneof: "lock_type" },
    { no: 11, name: "assa_abloy", kind: "message", T: Reservation_MobileKey_AssaAbloy, oneof: "lock_type" },
    { no: 12, name: "salto", kind: "message", T: Reservation_MobileKey_Salto, oneof: "lock_type" },
    { no: 20, name: "allowed", kind: "message", T: Reservation_MobileKey_Allowed, oneof: "status" },
    { no: 21, name: "revoked", kind: "message", T: Reservation_MobileKey_Revoked, oneof: "status" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_MobileKey {
    return new Reservation_MobileKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_MobileKey {
    return new Reservation_MobileKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_MobileKey {
    return new Reservation_MobileKey().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_MobileKey | PlainMessage<Reservation_MobileKey> | undefined, b: Reservation_MobileKey | PlainMessage<Reservation_MobileKey> | undefined): boolean {
    return proto3.util.equals(Reservation_MobileKey, a, b);
  }
}

/**
 * Using messages to easily extend the mobile key status.
 *
 * @generated from message shackle.backend.v1.Reservation.MobileKey.Allowed
 */
export class Reservation_MobileKey_Allowed extends Message<Reservation_MobileKey_Allowed> {
  constructor(data?: PartialMessage<Reservation_MobileKey_Allowed>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.MobileKey.Allowed";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_MobileKey_Allowed {
    return new Reservation_MobileKey_Allowed().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Allowed {
    return new Reservation_MobileKey_Allowed().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Allowed {
    return new Reservation_MobileKey_Allowed().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_MobileKey_Allowed | PlainMessage<Reservation_MobileKey_Allowed> | undefined, b: Reservation_MobileKey_Allowed | PlainMessage<Reservation_MobileKey_Allowed> | undefined): boolean {
    return proto3.util.equals(Reservation_MobileKey_Allowed, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.MobileKey.Revoked
 */
export class Reservation_MobileKey_Revoked extends Message<Reservation_MobileKey_Revoked> {
  constructor(data?: PartialMessage<Reservation_MobileKey_Revoked>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.MobileKey.Revoked";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_MobileKey_Revoked {
    return new Reservation_MobileKey_Revoked().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Revoked {
    return new Reservation_MobileKey_Revoked().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Revoked {
    return new Reservation_MobileKey_Revoked().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_MobileKey_Revoked | PlainMessage<Reservation_MobileKey_Revoked> | undefined, b: Reservation_MobileKey_Revoked | PlainMessage<Reservation_MobileKey_Revoked> | undefined): boolean {
    return proto3.util.equals(Reservation_MobileKey_Revoked, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.MobileKey.Dormakaba
 */
export class Reservation_MobileKey_Dormakaba extends Message<Reservation_MobileKey_Dormakaba> {
  constructor(data?: PartialMessage<Reservation_MobileKey_Dormakaba>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.MobileKey.Dormakaba";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_MobileKey_Dormakaba {
    return new Reservation_MobileKey_Dormakaba().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Dormakaba {
    return new Reservation_MobileKey_Dormakaba().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Dormakaba {
    return new Reservation_MobileKey_Dormakaba().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_MobileKey_Dormakaba | PlainMessage<Reservation_MobileKey_Dormakaba> | undefined, b: Reservation_MobileKey_Dormakaba | PlainMessage<Reservation_MobileKey_Dormakaba> | undefined): boolean {
    return proto3.util.equals(Reservation_MobileKey_Dormakaba, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.MobileKey.AssaAbloy
 */
export class Reservation_MobileKey_AssaAbloy extends Message<Reservation_MobileKey_AssaAbloy> {
  /**
   *
   * Lock Service Code is a numerical value that forms part of the Bluetooth service advertisement from Assa Abloy Hospitality (AAH) locks.
   * Each lock can only advertise a single Lock Service Code The value typically matches a hotel chain, or equivalent business unit,
   * thus segregating AAH locks into various channels. All AAH locks at any given site will be configured to advertise the same value.
   * This is the same as the UUID setting in Visionline. It will be 1 for testing purposes.
   *
   * @generated from field: int32 lock_service_code = 1;
   */
  lockServiceCode = 0;

  constructor(data?: PartialMessage<Reservation_MobileKey_AssaAbloy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.MobileKey.AssaAbloy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lock_service_code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_MobileKey_AssaAbloy {
    return new Reservation_MobileKey_AssaAbloy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_MobileKey_AssaAbloy {
    return new Reservation_MobileKey_AssaAbloy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_MobileKey_AssaAbloy {
    return new Reservation_MobileKey_AssaAbloy().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_MobileKey_AssaAbloy | PlainMessage<Reservation_MobileKey_AssaAbloy> | undefined, b: Reservation_MobileKey_AssaAbloy | PlainMessage<Reservation_MobileKey_AssaAbloy> | undefined): boolean {
    return proto3.util.equals(Reservation_MobileKey_AssaAbloy, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.MobileKey.Salto
 */
export class Reservation_MobileKey_Salto extends Message<Reservation_MobileKey_Salto> {
  constructor(data?: PartialMessage<Reservation_MobileKey_Salto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.MobileKey.Salto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_MobileKey_Salto {
    return new Reservation_MobileKey_Salto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Salto {
    return new Reservation_MobileKey_Salto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_MobileKey_Salto {
    return new Reservation_MobileKey_Salto().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_MobileKey_Salto | PlainMessage<Reservation_MobileKey_Salto> | undefined, b: Reservation_MobileKey_Salto | PlainMessage<Reservation_MobileKey_Salto> | undefined): boolean {
    return proto3.util.equals(Reservation_MobileKey_Salto, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.DormakabaLock
 */
export class Reservation_DormakabaLock extends Message<Reservation_DormakabaLock> {
  /**
   * Reservation id used by key cutting system in Dormakaba and put in metadata of key.
   *
   * @generated from field: string reservation_id = 1;
   */
  reservationId = "";

  constructor(data?: PartialMessage<Reservation_DormakabaLock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.DormakabaLock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_DormakabaLock {
    return new Reservation_DormakabaLock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_DormakabaLock {
    return new Reservation_DormakabaLock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_DormakabaLock {
    return new Reservation_DormakabaLock().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_DormakabaLock | PlainMessage<Reservation_DormakabaLock> | undefined, b: Reservation_DormakabaLock | PlainMessage<Reservation_DormakabaLock> | undefined): boolean {
    return proto3.util.equals(Reservation_DormakabaLock, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.Reservation.GuestMetadata
 */
export class Reservation_GuestMetadata extends Message<Reservation_GuestMetadata> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional string email = 2;
   */
  email?: string;

  /**
   * @generated from field: shackle.backend.v1.Reservation.GuestMetadata.Type type = 3;
   */
  type = Reservation_GuestMetadata_Type.UNKNOWN_GUEST_TYPE;

  constructor(data?: PartialMessage<Reservation_GuestMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.Reservation.GuestMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(Reservation_GuestMetadata_Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation_GuestMetadata {
    return new Reservation_GuestMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation_GuestMetadata {
    return new Reservation_GuestMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation_GuestMetadata {
    return new Reservation_GuestMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation_GuestMetadata | PlainMessage<Reservation_GuestMetadata> | undefined, b: Reservation_GuestMetadata | PlainMessage<Reservation_GuestMetadata> | undefined): boolean {
    return proto3.util.equals(Reservation_GuestMetadata, a, b);
  }
}

/**
 * @generated from enum shackle.backend.v1.Reservation.GuestMetadata.Type
 */
export enum Reservation_GuestMetadata_Type {
  /**
   * @generated from enum value: UNKNOWN_GUEST_TYPE = 0;
   */
  UNKNOWN_GUEST_TYPE = 0,

  /**
   * @generated from enum value: OWNER = 1;
   */
  OWNER = 1,

  /**
   * @generated from enum value: COMPANION = 2;
   */
  COMPANION = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Reservation_GuestMetadata_Type)
proto3.util.setEnumType(Reservation_GuestMetadata_Type, "shackle.backend.v1.Reservation.GuestMetadata.Type", [
  { no: 0, name: "UNKNOWN_GUEST_TYPE" },
  { no: 1, name: "OWNER" },
  { no: 2, name: "COMPANION" },
]);

/**
 * *
 * Requests and Responses.
 *
 * @generated from message shackle.backend.v1.CreateUserRequest
 */
export class CreateUserRequest extends Message<CreateUserRequest> {
  /**
   * Required. Firebase ID of the user to be created.
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * Required. User to be created.
   *
   * @generated from field: shackle.backend.v1.User user = 2;
   */
  user?: User;

  constructor(data?: PartialMessage<CreateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.CreateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateUserRequest | PlainMessage<CreateUserRequest> | undefined, b: CreateUserRequest | PlainMessage<CreateUserRequest> | undefined): boolean {
    return proto3.util.equals(CreateUserRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetUserRequest
 */
export class GetUserRequest extends Message<GetUserRequest> {
  /**
   * Required. Name of the user. Example: "users/asdkm". The identifier is the same as the 'localId' from Firebase.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserRequest {
    return new GetUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserRequest | PlainMessage<GetUserRequest> | undefined, b: GetUserRequest | PlainMessage<GetUserRequest> | undefined): boolean {
    return proto3.util.equals(GetUserRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.UpdateUserRequest
 */
export class UpdateUserRequest extends Message<UpdateUserRequest> {
  /**
   * Required. User to be updated.
   *
   * @generated from field: shackle.backend.v1.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<UpdateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.UpdateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined, b: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.SyncFirebaseUserRequest
 */
export class SyncFirebaseUserRequest extends Message<SyncFirebaseUserRequest> {
  /**
   * Required. Name of the user. Example: "users/12ldmik13d".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<SyncFirebaseUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.SyncFirebaseUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncFirebaseUserRequest {
    return new SyncFirebaseUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncFirebaseUserRequest {
    return new SyncFirebaseUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncFirebaseUserRequest {
    return new SyncFirebaseUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncFirebaseUserRequest | PlainMessage<SyncFirebaseUserRequest> | undefined, b: SyncFirebaseUserRequest | PlainMessage<SyncFirebaseUserRequest> | undefined): boolean {
    return proto3.util.equals(SyncFirebaseUserRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ValidatePhoneNumberRequest
 */
export class ValidatePhoneNumberRequest extends Message<ValidatePhoneNumberRequest> {
  /**
   * Required
   *
   * @generated from oneof shackle.backend.v1.ValidatePhoneNumberRequest.input
   */
  input: {
    /**
     * The phone number that needs to be validated.
     *
     * @generated from field: shackle.backend.v1.PhoneNumber phone_number = 1;
     */
    value: PhoneNumber;
    case: "phoneNumber";
  } | {
    /**
     * Free format text input from a user.
     *
     * @generated from field: shackle.backend.v1.ValidatePhoneNumberRequest.TextInput text_input = 2;
     */
    value: ValidatePhoneNumberRequest_TextInput;
    case: "textInput";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ValidatePhoneNumberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ValidatePhoneNumberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "message", T: PhoneNumber, oneof: "input" },
    { no: 2, name: "text_input", kind: "message", T: ValidatePhoneNumberRequest_TextInput, oneof: "input" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidatePhoneNumberRequest {
    return new ValidatePhoneNumberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidatePhoneNumberRequest {
    return new ValidatePhoneNumberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidatePhoneNumberRequest {
    return new ValidatePhoneNumberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidatePhoneNumberRequest | PlainMessage<ValidatePhoneNumberRequest> | undefined, b: ValidatePhoneNumberRequest | PlainMessage<ValidatePhoneNumberRequest> | undefined): boolean {
    return proto3.util.equals(ValidatePhoneNumberRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ValidatePhoneNumberRequest.TextInput
 */
export class ValidatePhoneNumberRequest_TextInput extends Message<ValidatePhoneNumberRequest_TextInput> {
  /**
   * Required. Free format text from a user. Example: "06-123 456 78"
   *
   * @generated from field: string text = 1;
   */
  text = "";

  /**
   * Optional. ISO-3166 alpha-2 country code of the user. Example: "NL".
   *
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  constructor(data?: PartialMessage<ValidatePhoneNumberRequest_TextInput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ValidatePhoneNumberRequest.TextInput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidatePhoneNumberRequest_TextInput {
    return new ValidatePhoneNumberRequest_TextInput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidatePhoneNumberRequest_TextInput {
    return new ValidatePhoneNumberRequest_TextInput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidatePhoneNumberRequest_TextInput {
    return new ValidatePhoneNumberRequest_TextInput().fromJsonString(jsonString, options);
  }

  static equals(a: ValidatePhoneNumberRequest_TextInput | PlainMessage<ValidatePhoneNumberRequest_TextInput> | undefined, b: ValidatePhoneNumberRequest_TextInput | PlainMessage<ValidatePhoneNumberRequest_TextInput> | undefined): boolean {
    return proto3.util.equals(ValidatePhoneNumberRequest_TextInput, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ValidatePhoneNumberResponse
 */
export class ValidatePhoneNumberResponse extends Message<ValidatePhoneNumberResponse> {
  /**
   * Whether the phone number was valid.
   *
   * @generated from field: bool valid = 1;
   */
  valid = false;

  /**
   * Formatted phone number.
   * If phone number was not valid it can be empty, but does not have to be.
   *
   * @generated from field: shackle.backend.v1.PhoneNumber phone_number = 2;
   */
  phoneNumber?: PhoneNumber;

  constructor(data?: PartialMessage<ValidatePhoneNumberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ValidatePhoneNumberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "phone_number", kind: "message", T: PhoneNumber },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidatePhoneNumberResponse {
    return new ValidatePhoneNumberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidatePhoneNumberResponse {
    return new ValidatePhoneNumberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidatePhoneNumberResponse {
    return new ValidatePhoneNumberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidatePhoneNumberResponse | PlainMessage<ValidatePhoneNumberResponse> | undefined, b: ValidatePhoneNumberResponse | PlainMessage<ValidatePhoneNumberResponse> | undefined): boolean {
    return proto3.util.equals(ValidatePhoneNumberResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.StartPhoneNumberVerificationRequest
 */
export class StartPhoneNumberVerificationRequest extends Message<StartPhoneNumberVerificationRequest> {
  /**
   * Required. Name of the user. Example: "users/12ldmik13d".
   *
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * Required. The phone number that needs to be verified.
   *
   * @generated from field: shackle.backend.v1.PhoneNumber phone_number = 2;
   */
  phoneNumber?: PhoneNumber;

  constructor(data?: PartialMessage<StartPhoneNumberVerificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.StartPhoneNumberVerificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "message", T: PhoneNumber },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartPhoneNumberVerificationRequest {
    return new StartPhoneNumberVerificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartPhoneNumberVerificationRequest {
    return new StartPhoneNumberVerificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartPhoneNumberVerificationRequest {
    return new StartPhoneNumberVerificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartPhoneNumberVerificationRequest | PlainMessage<StartPhoneNumberVerificationRequest> | undefined, b: StartPhoneNumberVerificationRequest | PlainMessage<StartPhoneNumberVerificationRequest> | undefined): boolean {
    return proto3.util.equals(StartPhoneNumberVerificationRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.StartPhoneNumberVerificationResponse
 */
export class StartPhoneNumberVerificationResponse extends Message<StartPhoneNumberVerificationResponse> {
  constructor(data?: PartialMessage<StartPhoneNumberVerificationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.StartPhoneNumberVerificationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartPhoneNumberVerificationResponse {
    return new StartPhoneNumberVerificationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartPhoneNumberVerificationResponse {
    return new StartPhoneNumberVerificationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartPhoneNumberVerificationResponse {
    return new StartPhoneNumberVerificationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartPhoneNumberVerificationResponse | PlainMessage<StartPhoneNumberVerificationResponse> | undefined, b: StartPhoneNumberVerificationResponse | PlainMessage<StartPhoneNumberVerificationResponse> | undefined): boolean {
    return proto3.util.equals(StartPhoneNumberVerificationResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.VerifyPhoneNumberRequest
 */
export class VerifyPhoneNumberRequest extends Message<VerifyPhoneNumberRequest> {
  /**
   * Required. Name of the user. Example: "users/12ldmik13d".
   *
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * Required. The verification code without spaces. Example: "123456"
   * Only 4-10 digits are allowed as value.
   *
   * @generated from field: string verification_code = 2;
   */
  verificationCode = "";

  constructor(data?: PartialMessage<VerifyPhoneNumberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.VerifyPhoneNumberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyPhoneNumberRequest {
    return new VerifyPhoneNumberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyPhoneNumberRequest {
    return new VerifyPhoneNumberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyPhoneNumberRequest {
    return new VerifyPhoneNumberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyPhoneNumberRequest | PlainMessage<VerifyPhoneNumberRequest> | undefined, b: VerifyPhoneNumberRequest | PlainMessage<VerifyPhoneNumberRequest> | undefined): boolean {
    return proto3.util.equals(VerifyPhoneNumberRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.VerifyPhoneNumberResponse
 */
export class VerifyPhoneNumberResponse extends Message<VerifyPhoneNumberResponse> {
  /**
   * Result of verification. If true the phone number is put on the user's profile.
   *
   * @generated from field: bool result = 1;
   */
  result = false;

  /**
   * If there are any attempts left
   *
   * @generated from field: bool attempts_left = 2;
   */
  attemptsLeft = false;

  constructor(data?: PartialMessage<VerifyPhoneNumberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.VerifyPhoneNumberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "result", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "attempts_left", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyPhoneNumberResponse {
    return new VerifyPhoneNumberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyPhoneNumberResponse {
    return new VerifyPhoneNumberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyPhoneNumberResponse {
    return new VerifyPhoneNumberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyPhoneNumberResponse | PlainMessage<VerifyPhoneNumberResponse> | undefined, b: VerifyPhoneNumberResponse | PlainMessage<VerifyPhoneNumberResponse> | undefined): boolean {
    return proto3.util.equals(VerifyPhoneNumberResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ListReservationsRequest
 */
export class ListReservationsRequest extends Message<ListReservationsRequest> {
  /**
   * Required. Name of the parent (user) resource. Example: "users/1odmi3aq".
   *
   * ref to user
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * Page token, see [standard fields](https://cloud.google.com/apis/design/standard_fields).
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListReservationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ListReservationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReservationsRequest {
    return new ListReservationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReservationsRequest {
    return new ListReservationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReservationsRequest {
    return new ListReservationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListReservationsRequest | PlainMessage<ListReservationsRequest> | undefined, b: ListReservationsRequest | PlainMessage<ListReservationsRequest> | undefined): boolean {
    return proto3.util.equals(ListReservationsRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ListReservationsResponse
 */
export class ListReservationsResponse extends Message<ListReservationsResponse> {
  /**
   * Reservations of the user.
   *
   * @generated from field: repeated shackle.backend.v1.Reservation user_reservation = 1 [deprecated = true];
   * @deprecated
   */
  userReservation: Reservation[] = [];

  /**
   * Next page token, see [standard fields](https://cloud.google.com/apis/design/standard_fields).
   * This was added as good practice, if pagination is needed, we will plan it better once is needed, so far users do not have many resvs to be paginated
   *
   * @generated from field: string next_page_token = 2 [deprecated = true];
   * @deprecated
   */
  nextPageToken = "";

  /**
   * @generated from field: repeated shackle.backend.v1.Reservation upcoming_reservation = 3;
   */
  upcomingReservation: Reservation[] = [];

  /**
   * @generated from field: repeated shackle.backend.v1.Reservation previous_reservation = 4;
   */
  previousReservation: Reservation[] = [];

  constructor(data?: PartialMessage<ListReservationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ListReservationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_reservation", kind: "message", T: Reservation, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "upcoming_reservation", kind: "message", T: Reservation, repeated: true },
    { no: 4, name: "previous_reservation", kind: "message", T: Reservation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReservationsResponse {
    return new ListReservationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReservationsResponse {
    return new ListReservationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReservationsResponse {
    return new ListReservationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListReservationsResponse | PlainMessage<ListReservationsResponse> | undefined, b: ListReservationsResponse | PlainMessage<ListReservationsResponse> | undefined): boolean {
    return proto3.util.equals(ListReservationsResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ClaimReservationRequest
 */
export class ClaimReservationRequest extends Message<ClaimReservationRequest> {
  /**
   * Required. Name of the parent (user) resource. Example: "users/1odmi3aq".
   *
   * ref to user
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * Required. Name of the property. Example: "properties/d1m03id1pi"
   *
   * @generated from field: string property_name = 2;
   */
  propertyName = "";

  /**
   * Confirmation number from the property's acknowledgement of the reservation. Usually sent via
   * e-mail after booking.
   *
   * @generated from field: string confirmation_number = 3;
   */
  confirmationNumber = "";

  constructor(data?: PartialMessage<ClaimReservationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ClaimReservationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "property_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "confirmation_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClaimReservationRequest {
    return new ClaimReservationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClaimReservationRequest {
    return new ClaimReservationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClaimReservationRequest {
    return new ClaimReservationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ClaimReservationRequest | PlainMessage<ClaimReservationRequest> | undefined, b: ClaimReservationRequest | PlainMessage<ClaimReservationRequest> | undefined): boolean {
    return proto3.util.equals(ClaimReservationRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetReservationRequest
 */
export class GetReservationRequest extends Message<GetReservationRequest> {
  /**
   * Required. Name of the reservation. Example: "users/12ldmik13d/reservations/1d3niofmi2".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetReservationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetReservationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetReservationRequest {
    return new GetReservationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetReservationRequest {
    return new GetReservationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetReservationRequest {
    return new GetReservationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetReservationRequest | PlainMessage<GetReservationRequest> | undefined, b: GetReservationRequest | PlainMessage<GetReservationRequest> | undefined): boolean {
    return proto3.util.equals(GetReservationRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetPrimaryGuestPersonalDetailsRequest
 */
export class GetPrimaryGuestPersonalDetailsRequest extends Message<GetPrimaryGuestPersonalDetailsRequest> {
  /**
   * Required. Name of the reservation. Example: "users/12ldmik13d/reservations/1d3niofmi2".
   *
   * @generated from field: string reservation_name = 1;
   */
  reservationName = "";

  constructor(data?: PartialMessage<GetPrimaryGuestPersonalDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetPrimaryGuestPersonalDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPrimaryGuestPersonalDetailsRequest {
    return new GetPrimaryGuestPersonalDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPrimaryGuestPersonalDetailsRequest {
    return new GetPrimaryGuestPersonalDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPrimaryGuestPersonalDetailsRequest {
    return new GetPrimaryGuestPersonalDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPrimaryGuestPersonalDetailsRequest | PlainMessage<GetPrimaryGuestPersonalDetailsRequest> | undefined, b: GetPrimaryGuestPersonalDetailsRequest | PlainMessage<GetPrimaryGuestPersonalDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetPrimaryGuestPersonalDetailsRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetPrimaryGuestPersonalDetailsResponse
 */
export class GetPrimaryGuestPersonalDetailsResponse extends Message<GetPrimaryGuestPersonalDetailsResponse> {
  /**
   * @generated from field: optional string email = 1;
   */
  email?: string;

  constructor(data?: PartialMessage<GetPrimaryGuestPersonalDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetPrimaryGuestPersonalDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPrimaryGuestPersonalDetailsResponse {
    return new GetPrimaryGuestPersonalDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPrimaryGuestPersonalDetailsResponse {
    return new GetPrimaryGuestPersonalDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPrimaryGuestPersonalDetailsResponse {
    return new GetPrimaryGuestPersonalDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPrimaryGuestPersonalDetailsResponse | PlainMessage<GetPrimaryGuestPersonalDetailsResponse> | undefined, b: GetPrimaryGuestPersonalDetailsResponse | PlainMessage<GetPrimaryGuestPersonalDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetPrimaryGuestPersonalDetailsResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ListPropertiesRequest
 */
export class ListPropertiesRequest extends Message<ListPropertiesRequest> {
  /**
   * Page token, see [standard fields](https://cloud.google.com/apis/design/standard_fields).
   *
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListPropertiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ListPropertiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPropertiesRequest {
    return new ListPropertiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPropertiesRequest {
    return new ListPropertiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPropertiesRequest {
    return new ListPropertiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPropertiesRequest | PlainMessage<ListPropertiesRequest> | undefined, b: ListPropertiesRequest | PlainMessage<ListPropertiesRequest> | undefined): boolean {
    return proto3.util.equals(ListPropertiesRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.ListPropertiesResponse
 */
export class ListPropertiesResponse extends Message<ListPropertiesResponse> {
  /**
   * Required. Matching properties.
   *
   * @generated from field: repeated shackle.common.Property property = 1;
   */
  property: Property[] = [];

  /**
   * Next page token, see [standard fields](https://cloud.google.com/apis/design/standard_fields).
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListPropertiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.ListPropertiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "property", kind: "message", T: Property, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPropertiesResponse {
    return new ListPropertiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPropertiesResponse {
    return new ListPropertiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPropertiesResponse {
    return new ListPropertiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPropertiesResponse | PlainMessage<ListPropertiesResponse> | undefined, b: ListPropertiesResponse | PlainMessage<ListPropertiesResponse> | undefined): boolean {
    return proto3.util.equals(ListPropertiesResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.SearchPropertyRequest
 */
export class SearchPropertyRequest extends Message<SearchPropertyRequest> {
  /**
   * Required. Text to match property titles with.
   *
   * @generated from field: string search_query = 1;
   */
  searchQuery = "";

  /**
   * Page token, see [standard fields](https://cloud.google.com/apis/design/standard_fields).
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<SearchPropertyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.SearchPropertyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchPropertyRequest {
    return new SearchPropertyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchPropertyRequest {
    return new SearchPropertyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchPropertyRequest {
    return new SearchPropertyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchPropertyRequest | PlainMessage<SearchPropertyRequest> | undefined, b: SearchPropertyRequest | PlainMessage<SearchPropertyRequest> | undefined): boolean {
    return proto3.util.equals(SearchPropertyRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.SearchPropertyResponse
 */
export class SearchPropertyResponse extends Message<SearchPropertyResponse> {
  /**
   * Required. Matching properties.
   *
   * @generated from field: repeated shackle.common.Property property = 1;
   */
  property: Property[] = [];

  /**
   * Next page token, see [standard fields](https://cloud.google.com/apis/design/standard_fields).
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<SearchPropertyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.SearchPropertyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "property", kind: "message", T: Property, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchPropertyResponse {
    return new SearchPropertyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchPropertyResponse {
    return new SearchPropertyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchPropertyResponse {
    return new SearchPropertyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchPropertyResponse | PlainMessage<SearchPropertyResponse> | undefined, b: SearchPropertyResponse | PlainMessage<SearchPropertyResponse> | undefined): boolean {
    return proto3.util.equals(SearchPropertyResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetPropertyRequest
 */
export class GetPropertyRequest extends Message<GetPropertyRequest> {
  /**
   * Required. Name of the property. Example: "properties/d13ui1o3dl".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetPropertyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetPropertyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPropertyRequest {
    return new GetPropertyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPropertyRequest {
    return new GetPropertyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPropertyRequest {
    return new GetPropertyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPropertyRequest | PlainMessage<GetPropertyRequest> | undefined, b: GetPropertyRequest | PlainMessage<GetPropertyRequest> | undefined): boolean {
    return proto3.util.equals(GetPropertyRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.CheckOutRequest
 */
export class CheckOutRequest extends Message<CheckOutRequest> {
  /**
   * Required. Name of the reservation. Ex: "users/qwdklqwkld/reservations/qlwkd"
   *
   * @generated from field: string reservation_name = 1;
   */
  reservationName = "";

  constructor(data?: PartialMessage<CheckOutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.CheckOutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckOutRequest {
    return new CheckOutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckOutRequest {
    return new CheckOutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckOutRequest {
    return new CheckOutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckOutRequest | PlainMessage<CheckOutRequest> | undefined, b: CheckOutRequest | PlainMessage<CheckOutRequest> | undefined): boolean {
    return proto3.util.equals(CheckOutRequest, a, b);
  }
}

/**
 * Empty, so it can be extended easier later.
 *
 * @generated from message shackle.backend.v1.PreregisterDormakabaDeviceRequest
 */
export class PreregisterDormakabaDeviceRequest extends Message<PreregisterDormakabaDeviceRequest> {
  constructor(data?: PartialMessage<PreregisterDormakabaDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.PreregisterDormakabaDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreregisterDormakabaDeviceRequest {
    return new PreregisterDormakabaDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreregisterDormakabaDeviceRequest {
    return new PreregisterDormakabaDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreregisterDormakabaDeviceRequest {
    return new PreregisterDormakabaDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PreregisterDormakabaDeviceRequest | PlainMessage<PreregisterDormakabaDeviceRequest> | undefined, b: PreregisterDormakabaDeviceRequest | PlainMessage<PreregisterDormakabaDeviceRequest> | undefined): boolean {
    return proto3.util.equals(PreregisterDormakabaDeviceRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.PreregisterDormakabaDeviceResponse
 */
export class PreregisterDormakabaDeviceResponse extends Message<PreregisterDormakabaDeviceResponse> {
  /**
   * Register the app instance in the Dormakaba SDK with this device identifier. Backend
   * automatically provision keys for linked reservations with properties using Dormakaba as
   * provider.
   *
   * @generated from field: string device_id = 1;
   */
  deviceId = "";

  /**
   * Token to authenticate the device. Use this once to register the device.
   *
   * @generated from field: string token = 2;
   */
  token = "";

  constructor(data?: PartialMessage<PreregisterDormakabaDeviceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.PreregisterDormakabaDeviceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreregisterDormakabaDeviceResponse {
    return new PreregisterDormakabaDeviceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreregisterDormakabaDeviceResponse {
    return new PreregisterDormakabaDeviceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreregisterDormakabaDeviceResponse {
    return new PreregisterDormakabaDeviceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PreregisterDormakabaDeviceResponse | PlainMessage<PreregisterDormakabaDeviceResponse> | undefined, b: PreregisterDormakabaDeviceResponse | PlainMessage<PreregisterDormakabaDeviceResponse> | undefined): boolean {
    return proto3.util.equals(PreregisterDormakabaDeviceResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.InitializeDormakabaDeviceRequest
 */
export class InitializeDormakabaDeviceRequest extends Message<InitializeDormakabaDeviceRequest> {
  /**
   * Required. Dormakaba device id. Ex: "db1uy3d13d79adzZ".
   *
   * @generated from field: string device_id = 2;
   */
  deviceId = "";

  constructor(data?: PartialMessage<InitializeDormakabaDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.InitializeDormakabaDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitializeDormakabaDeviceRequest {
    return new InitializeDormakabaDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitializeDormakabaDeviceRequest {
    return new InitializeDormakabaDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitializeDormakabaDeviceRequest {
    return new InitializeDormakabaDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitializeDormakabaDeviceRequest | PlainMessage<InitializeDormakabaDeviceRequest> | undefined, b: InitializeDormakabaDeviceRequest | PlainMessage<InitializeDormakabaDeviceRequest> | undefined): boolean {
    return proto3.util.equals(InitializeDormakabaDeviceRequest, a, b);
  }
}

/**
 * Empty, for later extension.
 *
 * @generated from message shackle.backend.v1.InitializeDormakabaDeviceResponse
 */
export class InitializeDormakabaDeviceResponse extends Message<InitializeDormakabaDeviceResponse> {
  constructor(data?: PartialMessage<InitializeDormakabaDeviceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.InitializeDormakabaDeviceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitializeDormakabaDeviceResponse {
    return new InitializeDormakabaDeviceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitializeDormakabaDeviceResponse {
    return new InitializeDormakabaDeviceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitializeDormakabaDeviceResponse {
    return new InitializeDormakabaDeviceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitializeDormakabaDeviceResponse | PlainMessage<InitializeDormakabaDeviceResponse> | undefined, b: InitializeDormakabaDeviceResponse | PlainMessage<InitializeDormakabaDeviceResponse> | undefined): boolean {
    return proto3.util.equals(InitializeDormakabaDeviceResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.RemoveDormakabaDeviceRequest
 */
export class RemoveDormakabaDeviceRequest extends Message<RemoveDormakabaDeviceRequest> {
  /**
   * Required. Dormakaba device id. Ex: "db1uy3d13d79adzZ".
   *
   * @generated from field: string device_id = 2;
   */
  deviceId = "";

  constructor(data?: PartialMessage<RemoveDormakabaDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.RemoveDormakabaDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveDormakabaDeviceRequest {
    return new RemoveDormakabaDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveDormakabaDeviceRequest {
    return new RemoveDormakabaDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveDormakabaDeviceRequest {
    return new RemoveDormakabaDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveDormakabaDeviceRequest | PlainMessage<RemoveDormakabaDeviceRequest> | undefined, b: RemoveDormakabaDeviceRequest | PlainMessage<RemoveDormakabaDeviceRequest> | undefined): boolean {
    return proto3.util.equals(RemoveDormakabaDeviceRequest, a, b);
  }
}

/**
 * Empty, for later extension.
 *
 * @generated from message shackle.backend.v1.RemoveDormakabaDeviceResponse
 */
export class RemoveDormakabaDeviceResponse extends Message<RemoveDormakabaDeviceResponse> {
  constructor(data?: PartialMessage<RemoveDormakabaDeviceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.RemoveDormakabaDeviceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveDormakabaDeviceResponse {
    return new RemoveDormakabaDeviceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveDormakabaDeviceResponse {
    return new RemoveDormakabaDeviceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveDormakabaDeviceResponse {
    return new RemoveDormakabaDeviceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveDormakabaDeviceResponse | PlainMessage<RemoveDormakabaDeviceResponse> | undefined, b: RemoveDormakabaDeviceResponse | PlainMessage<RemoveDormakabaDeviceResponse> | undefined): boolean {
    return proto3.util.equals(RemoveDormakabaDeviceResponse, a, b);
  }
}

/**
 * Empty, for later extension.
 *
 * @generated from message shackle.backend.v1.GetAssaAbloyInvitationCodeRequest
 */
export class GetAssaAbloyInvitationCodeRequest extends Message<GetAssaAbloyInvitationCodeRequest> {
  constructor(data?: PartialMessage<GetAssaAbloyInvitationCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetAssaAbloyInvitationCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssaAbloyInvitationCodeRequest {
    return new GetAssaAbloyInvitationCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssaAbloyInvitationCodeRequest {
    return new GetAssaAbloyInvitationCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssaAbloyInvitationCodeRequest {
    return new GetAssaAbloyInvitationCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssaAbloyInvitationCodeRequest | PlainMessage<GetAssaAbloyInvitationCodeRequest> | undefined, b: GetAssaAbloyInvitationCodeRequest | PlainMessage<GetAssaAbloyInvitationCodeRequest> | undefined): boolean {
    return proto3.util.equals(GetAssaAbloyInvitationCodeRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetAssaAbloyInvitationCodeResponse
 */
export class GetAssaAbloyInvitationCodeResponse extends Message<GetAssaAbloyInvitationCodeResponse> {
  /**
   * Unique identifier of the endpoint, ex: "dPQTT".
   *
   * @generated from field: string endpoint_id = 1;
   */
  endpointId = "";

  /**
   * Token that should be passed to mobile to finish registration.
   *
   * @generated from field: string invitation_code = 2;
   */
  invitationCode = "";

  constructor(data?: PartialMessage<GetAssaAbloyInvitationCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetAssaAbloyInvitationCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "endpoint_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "invitation_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssaAbloyInvitationCodeResponse {
    return new GetAssaAbloyInvitationCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssaAbloyInvitationCodeResponse {
    return new GetAssaAbloyInvitationCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssaAbloyInvitationCodeResponse {
    return new GetAssaAbloyInvitationCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssaAbloyInvitationCodeResponse | PlainMessage<GetAssaAbloyInvitationCodeResponse> | undefined, b: GetAssaAbloyInvitationCodeResponse | PlainMessage<GetAssaAbloyInvitationCodeResponse> | undefined): boolean {
    return proto3.util.equals(GetAssaAbloyInvitationCodeResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.RemoveAssaAbloyEndpointRequest
 */
export class RemoveAssaAbloyEndpointRequest extends Message<RemoveAssaAbloyEndpointRequest> {
  /**
   * Unique identifier of the endpoint, ex: "dPQTT".
   *
   * @generated from field: string endpoint_id = 1;
   */
  endpointId = "";

  constructor(data?: PartialMessage<RemoveAssaAbloyEndpointRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.RemoveAssaAbloyEndpointRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "endpoint_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveAssaAbloyEndpointRequest {
    return new RemoveAssaAbloyEndpointRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveAssaAbloyEndpointRequest {
    return new RemoveAssaAbloyEndpointRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveAssaAbloyEndpointRequest {
    return new RemoveAssaAbloyEndpointRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveAssaAbloyEndpointRequest | PlainMessage<RemoveAssaAbloyEndpointRequest> | undefined, b: RemoveAssaAbloyEndpointRequest | PlainMessage<RemoveAssaAbloyEndpointRequest> | undefined): boolean {
    return proto3.util.equals(RemoveAssaAbloyEndpointRequest, a, b);
  }
}

/**
 * Empty, for later extension.
 *
 * @generated from message shackle.backend.v1.RemoveAssaAbloyEndpointResponse
 */
export class RemoveAssaAbloyEndpointResponse extends Message<RemoveAssaAbloyEndpointResponse> {
  constructor(data?: PartialMessage<RemoveAssaAbloyEndpointResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.RemoveAssaAbloyEndpointResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveAssaAbloyEndpointResponse {
    return new RemoveAssaAbloyEndpointResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveAssaAbloyEndpointResponse {
    return new RemoveAssaAbloyEndpointResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveAssaAbloyEndpointResponse {
    return new RemoveAssaAbloyEndpointResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveAssaAbloyEndpointResponse | PlainMessage<RemoveAssaAbloyEndpointResponse> | undefined, b: RemoveAssaAbloyEndpointResponse | PlainMessage<RemoveAssaAbloyEndpointResponse> | undefined): boolean {
    return proto3.util.equals(RemoveAssaAbloyEndpointResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.FinishAssaAbloyEndpointRegistrationRequest
 */
export class FinishAssaAbloyEndpointRegistrationRequest extends Message<FinishAssaAbloyEndpointRegistrationRequest> {
  /**
   * Unique identifier of the endpoint, ex: "dPQTT".
   *
   * @generated from field: string endpoint_id = 1;
   */
  endpointId = "";

  constructor(data?: PartialMessage<FinishAssaAbloyEndpointRegistrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.FinishAssaAbloyEndpointRegistrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "endpoint_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinishAssaAbloyEndpointRegistrationRequest {
    return new FinishAssaAbloyEndpointRegistrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishAssaAbloyEndpointRegistrationRequest {
    return new FinishAssaAbloyEndpointRegistrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinishAssaAbloyEndpointRegistrationRequest {
    return new FinishAssaAbloyEndpointRegistrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FinishAssaAbloyEndpointRegistrationRequest | PlainMessage<FinishAssaAbloyEndpointRegistrationRequest> | undefined, b: FinishAssaAbloyEndpointRegistrationRequest | PlainMessage<FinishAssaAbloyEndpointRegistrationRequest> | undefined): boolean {
    return proto3.util.equals(FinishAssaAbloyEndpointRegistrationRequest, a, b);
  }
}

/**
 * Empty, for later extension.
 *
 * @generated from message shackle.backend.v1.FinishAssaAbloyEndpointRegistrationResponse
 */
export class FinishAssaAbloyEndpointRegistrationResponse extends Message<FinishAssaAbloyEndpointRegistrationResponse> {
  constructor(data?: PartialMessage<FinishAssaAbloyEndpointRegistrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.FinishAssaAbloyEndpointRegistrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinishAssaAbloyEndpointRegistrationResponse {
    return new FinishAssaAbloyEndpointRegistrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishAssaAbloyEndpointRegistrationResponse {
    return new FinishAssaAbloyEndpointRegistrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinishAssaAbloyEndpointRegistrationResponse {
    return new FinishAssaAbloyEndpointRegistrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FinishAssaAbloyEndpointRegistrationResponse | PlainMessage<FinishAssaAbloyEndpointRegistrationResponse> | undefined, b: FinishAssaAbloyEndpointRegistrationResponse | PlainMessage<FinishAssaAbloyEndpointRegistrationResponse> | undefined): boolean {
    return proto3.util.equals(FinishAssaAbloyEndpointRegistrationResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.LogAccessAttemptRequest
 */
export class LogAccessAttemptRequest extends Message<LogAccessAttemptRequest> {
  /**
   * Required. Name of the reservation. Ex: "users/qwdklqwkld/reservations/qlwkd"
   *
   * @generated from field: string reservation_name = 1;
   */
  reservationName = "";

  /**
   * Required. Timestamp of attempt.
   *
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * Required. Successful response of lock or not.
   *
   * @generated from field: bool successful = 3;
   */
  successful = false;

  /**
   * Required. The lock's device id.
   *
   * @generated from field: string lock_device_id = 4;
   */
  lockDeviceId = "";

  constructor(data?: PartialMessage<LogAccessAttemptRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.LogAccessAttemptRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "successful", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "lock_device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogAccessAttemptRequest {
    return new LogAccessAttemptRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogAccessAttemptRequest {
    return new LogAccessAttemptRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogAccessAttemptRequest {
    return new LogAccessAttemptRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogAccessAttemptRequest | PlainMessage<LogAccessAttemptRequest> | undefined, b: LogAccessAttemptRequest | PlainMessage<LogAccessAttemptRequest> | undefined): boolean {
    return proto3.util.equals(LogAccessAttemptRequest, a, b);
  }
}

/**
 * Empty, for later extension.
 *
 * @generated from message shackle.backend.v1.LogAccessAttemptResponse
 */
export class LogAccessAttemptResponse extends Message<LogAccessAttemptResponse> {
  constructor(data?: PartialMessage<LogAccessAttemptResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.LogAccessAttemptResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogAccessAttemptResponse {
    return new LogAccessAttemptResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogAccessAttemptResponse {
    return new LogAccessAttemptResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogAccessAttemptResponse {
    return new LogAccessAttemptResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogAccessAttemptResponse | PlainMessage<LogAccessAttemptResponse> | undefined, b: LogAccessAttemptResponse | PlainMessage<LogAccessAttemptResponse> | undefined): boolean {
    return proto3.util.equals(LogAccessAttemptResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.TravelDocumentScanRequest
 */
export class TravelDocumentScanRequest extends Message<TravelDocumentScanRequest> {
  /**
   * Required. Issuing state and name is represented. It should contain 44 characters.
   *
   * @generated from field: string mrzLine1 = 1;
   */
  mrzLine1 = "";

  /**
   * Required. General data of user is represented. It should contain 44 characters
   *
   * @generated from field: string mrzLine2 = 2;
   */
  mrzLine2 = "";

  constructor(data?: PartialMessage<TravelDocumentScanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.TravelDocumentScanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mrzLine1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mrzLine2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TravelDocumentScanRequest {
    return new TravelDocumentScanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TravelDocumentScanRequest {
    return new TravelDocumentScanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TravelDocumentScanRequest {
    return new TravelDocumentScanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TravelDocumentScanRequest | PlainMessage<TravelDocumentScanRequest> | undefined, b: TravelDocumentScanRequest | PlainMessage<TravelDocumentScanRequest> | undefined): boolean {
    return proto3.util.equals(TravelDocumentScanRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.TravelDocumentScanResponse
 */
export class TravelDocumentScanResponse extends Message<TravelDocumentScanResponse> {
  /**
   * Document type of scanned travel document. Currently only passport
   *
   * @generated from field: shackle.backend.v1.TravelDocumentType documentType = 1;
   */
  documentType = TravelDocumentType.OTHER;

  /**
   * ISO-3166 alpha-3 country code of the issuer of the guest's passport.
   *
   * @generated from field: string issuingState = 2;
   */
  issuingState = "";

  /**
   * Last name of passport holder
   *
   * @generated from field: string holderLastName = 3;
   */
  holderLastName = "";

  /**
   * First name of passport holder
   *
   * @generated from field: string holderFirstName = 4;
   */
  holderFirstName = "";

  /**
   * Document number of travel document
   *
   * @generated from field: string documentNumber = 5;
   */
  documentNumber = "";

  /**
   * ISO-3166 alpha-3 country code of the nationality of the guest's passport.
   *
   * @generated from field: string nationality = 6;
   */
  nationality = "";

  /**
   * Birth date of holder
   *
   * @generated from field: google.type.Date dateOfBirth = 7;
   */
  dateOfBirth?: Date;

  /**
   * Gender of Holder. Can be M, F or <
   *
   * @generated from field: string gender = 8;
   */
  gender = "";

  /**
   * Expire date of document
   *
   * @generated from field: google.type.Date dateOfExpiry = 9;
   */
  dateOfExpiry?: Date;

  /**
   * Country specific optional data
   *
   * @generated from field: string optionalData = 10;
   */
  optionalData = "";

  constructor(data?: PartialMessage<TravelDocumentScanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.TravelDocumentScanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documentType", kind: "enum", T: proto3.getEnumType(TravelDocumentType) },
    { no: 2, name: "issuingState", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "holderLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "holderFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "documentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "nationality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "dateOfBirth", kind: "message", T: Date },
    { no: 8, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "dateOfExpiry", kind: "message", T: Date },
    { no: 10, name: "optionalData", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TravelDocumentScanResponse {
    return new TravelDocumentScanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TravelDocumentScanResponse {
    return new TravelDocumentScanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TravelDocumentScanResponse {
    return new TravelDocumentScanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TravelDocumentScanResponse | PlainMessage<TravelDocumentScanResponse> | undefined, b: TravelDocumentScanResponse | PlainMessage<TravelDocumentScanResponse> | undefined): boolean {
    return proto3.util.equals(TravelDocumentScanResponse, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetInvoiceRequest
 */
export class GetInvoiceRequest extends Message<GetInvoiceRequest> {
  /**
   * Required. The name of the reservation. Ex: "hotel-reservations/qlwkdml3d".
   *
   * @generated from field: string reservation_name = 1;
   */
  reservationName = "";

  constructor(data?: PartialMessage<GetInvoiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetInvoiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInvoiceRequest {
    return new GetInvoiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInvoiceRequest {
    return new GetInvoiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInvoiceRequest {
    return new GetInvoiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInvoiceRequest | PlainMessage<GetInvoiceRequest> | undefined, b: GetInvoiceRequest | PlainMessage<GetInvoiceRequest> | undefined): boolean {
    return proto3.util.equals(GetInvoiceRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetInvoiceResponse
 */
export class GetInvoiceResponse extends Message<GetInvoiceResponse> {
  /**
   * Required. The name of the reservation. Ex: "hotel-reservations/qlwkdml3d/invoices/wvernlyneiv".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated shackle.common.InvoiceSection section = 2;
   */
  section: InvoiceSection[] = [];

  /**
   * @generated from field: shackle.common.Money total = 3;
   */
  total?: Money;

  /**
   * @generated from field: shackle.backend.v1.GetInvoiceResponse.Disclaimer disclaimer = 4;
   */
  disclaimer = GetInvoiceResponse_Disclaimer.DISCLAIMER_UNSPECIFIED;

  constructor(data?: PartialMessage<GetInvoiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetInvoiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "section", kind: "message", T: InvoiceSection, repeated: true },
    { no: 3, name: "total", kind: "message", T: Money },
    { no: 4, name: "disclaimer", kind: "enum", T: proto3.getEnumType(GetInvoiceResponse_Disclaimer) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInvoiceResponse {
    return new GetInvoiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInvoiceResponse {
    return new GetInvoiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInvoiceResponse {
    return new GetInvoiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInvoiceResponse | PlainMessage<GetInvoiceResponse> | undefined, b: GetInvoiceResponse | PlainMessage<GetInvoiceResponse> | undefined): boolean {
    return proto3.util.equals(GetInvoiceResponse, a, b);
  }
}

/**
 * @generated from enum shackle.backend.v1.GetInvoiceResponse.Disclaimer
 */
export enum GetInvoiceResponse_Disclaimer {
  /**
   * @generated from enum value: DISCLAIMER_UNSPECIFIED = 0;
   */
  DISCLAIMER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: RECEPTION_INTERVENTION_NEEDED = 1;
   */
  RECEPTION_INTERVENTION_NEEDED = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(GetInvoiceResponse_Disclaimer)
proto3.util.setEnumType(GetInvoiceResponse_Disclaimer, "shackle.backend.v1.GetInvoiceResponse.Disclaimer", [
  { no: 0, name: "DISCLAIMER_UNSPECIFIED" },
  { no: 1, name: "RECEPTION_INTERVENTION_NEEDED" },
]);

/**
 * @generated from message shackle.backend.v1.GetCheckoutPaymentStatusRequest
 */
export class GetCheckoutPaymentStatusRequest extends Message<GetCheckoutPaymentStatusRequest> {
  /**
   * Required. The name of the reservation. Ex: "hotel-reservations/qlwkdml3d".
   *
   * @generated from field: string reservation_name = 1;
   */
  reservationName = "";

  constructor(data?: PartialMessage<GetCheckoutPaymentStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetCheckoutPaymentStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCheckoutPaymentStatusRequest {
    return new GetCheckoutPaymentStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCheckoutPaymentStatusRequest {
    return new GetCheckoutPaymentStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCheckoutPaymentStatusRequest {
    return new GetCheckoutPaymentStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCheckoutPaymentStatusRequest | PlainMessage<GetCheckoutPaymentStatusRequest> | undefined, b: GetCheckoutPaymentStatusRequest | PlainMessage<GetCheckoutPaymentStatusRequest> | undefined): boolean {
    return proto3.util.equals(GetCheckoutPaymentStatusRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.GetCheckoutPaymentStatusResponse
 */
export class GetCheckoutPaymentStatusResponse extends Message<GetCheckoutPaymentStatusResponse> {
  /**
   * @generated from field: shackle.backend.v1.GetCheckoutPaymentStatusResponse.CheckoutPaymentStatus status = 1;
   */
  status = GetCheckoutPaymentStatusResponse_CheckoutPaymentStatus.UNKNOWN;

  /**
   * Single invoice should be deprecated when apps start using the invoices field (expanding the reach v2)
   * As for now invoice will get only the first invoice (window 1) for retro compatibility
   *
   * @generated from field: shackle.backend.v1.GetCheckoutPaymentStatusResponse.Invoice invoice = 2;
   */
  invoice?: GetCheckoutPaymentStatusResponse_Invoice;

  /**
   * Required. All invoices for the primary guest.
   *
   * @generated from field: repeated shackle.backend.v1.GetCheckoutPaymentStatusResponse.Invoice invoices = 3;
   */
  invoices: GetCheckoutPaymentStatusResponse_Invoice[] = [];

  /**
   * Optional. Sum of invoices total
   *
   * @generated from field: shackle.common.Money grand_total = 4;
   */
  grandTotal?: Money;

  /**
   * @generated from field: shackle.backend.v1.GetCheckoutPaymentStatusResponse.OnSuccessText on_success_text = 5;
   */
  onSuccessText = GetCheckoutPaymentStatusResponse_OnSuccessText._UNKNOWN;

  constructor(data?: PartialMessage<GetCheckoutPaymentStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetCheckoutPaymentStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetCheckoutPaymentStatusResponse_CheckoutPaymentStatus) },
    { no: 2, name: "invoice", kind: "message", T: GetCheckoutPaymentStatusResponse_Invoice },
    { no: 3, name: "invoices", kind: "message", T: GetCheckoutPaymentStatusResponse_Invoice, repeated: true },
    { no: 4, name: "grand_total", kind: "message", T: Money },
    { no: 5, name: "on_success_text", kind: "enum", T: proto3.getEnumType(GetCheckoutPaymentStatusResponse_OnSuccessText) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCheckoutPaymentStatusResponse {
    return new GetCheckoutPaymentStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCheckoutPaymentStatusResponse {
    return new GetCheckoutPaymentStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCheckoutPaymentStatusResponse {
    return new GetCheckoutPaymentStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCheckoutPaymentStatusResponse | PlainMessage<GetCheckoutPaymentStatusResponse> | undefined, b: GetCheckoutPaymentStatusResponse | PlainMessage<GetCheckoutPaymentStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetCheckoutPaymentStatusResponse, a, b);
  }
}

/**
 * @generated from enum shackle.backend.v1.GetCheckoutPaymentStatusResponse.CheckoutPaymentStatus
 */
export enum GetCheckoutPaymentStatusResponse_CheckoutPaymentStatus {
  /**
   * Do not use this, fallback value.
   *
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Reservation is ready for checkout.
   *
   * @generated from enum value: READY_FOR_CHECKOUT = 1;
   */
  READY_FOR_CHECKOUT = 1,

  /**
   * Reservation requires a payment
   *
   * @generated from enum value: PAYMENT_REQUIRED = 2;
   */
  PAYMENT_REQUIRED = 2,

  /**
   * Reservation cannot be checked out via app
   *
   * @generated from enum value: MANUAL_CHECKOUT_REQUIRED = 3;
   */
  MANUAL_CHECKOUT_REQUIRED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(GetCheckoutPaymentStatusResponse_CheckoutPaymentStatus)
proto3.util.setEnumType(GetCheckoutPaymentStatusResponse_CheckoutPaymentStatus, "shackle.backend.v1.GetCheckoutPaymentStatusResponse.CheckoutPaymentStatus", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "READY_FOR_CHECKOUT" },
  { no: 2, name: "PAYMENT_REQUIRED" },
  { no: 3, name: "MANUAL_CHECKOUT_REQUIRED" },
]);

/**
 * @generated from enum shackle.backend.v1.GetCheckoutPaymentStatusResponse.OnSuccessText
 */
export enum GetCheckoutPaymentStatusResponse_OnSuccessText {
  /**
   * Do not use this, fallback value.
   *
   * @generated from enum value: _UNKNOWN = 0;
   */
  _UNKNOWN = 0,

  /**
   * As point 2, we know a bill exists for the user but we know there are no other folios requiring payment. We only inform that the hotel will be sending a copy of the bill.
   *
   * @generated from enum value: BILL_TO_BE_EMAILED = 1;
   */
  BILL_TO_BE_EMAILED = 1,

  /**
   * We know a bill exists for the user (either if there is a pending balance, or if there are charges and a zero balance) and we know the reservation presents other folios requiring payment, we should inform the user that the hotel will be sending a copy of the bill and might be in contact about those other folios.
   *
   * @generated from enum value: BILL_TO_BE_EMAILED_EXPECT_HOTEL_CONTACT = 2;
   */
  BILL_TO_BE_EMAILED_EXPECT_HOTEL_CONTACT = 2,

  /**
   * No bill/charges present for primary guest, so no invoice will be sent but other folios require payment. We inform the user that the hotel might be in contact about those other folios.
   *
   * @generated from enum value: EXPECT_HOTEL_CONTACT = 3;
   */
  EXPECT_HOTEL_CONTACT = 3,

  /**
   * No bill/charges present for primary guest, so no invoice will be sent and there are no other folios requiring payment, so we only say the user is ready to leave.
   *
   * @generated from enum value: GOOD_TO_GO = 4;
   */
  GOOD_TO_GO = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(GetCheckoutPaymentStatusResponse_OnSuccessText)
proto3.util.setEnumType(GetCheckoutPaymentStatusResponse_OnSuccessText, "shackle.backend.v1.GetCheckoutPaymentStatusResponse.OnSuccessText", [
  { no: 0, name: "_UNKNOWN" },
  { no: 1, name: "BILL_TO_BE_EMAILED" },
  { no: 2, name: "BILL_TO_BE_EMAILED_EXPECT_HOTEL_CONTACT" },
  { no: 3, name: "EXPECT_HOTEL_CONTACT" },
  { no: 4, name: "GOOD_TO_GO" },
]);

/**
 * @generated from message shackle.backend.v1.GetCheckoutPaymentStatusResponse.Invoice
 */
export class GetCheckoutPaymentStatusResponse_Invoice extends Message<GetCheckoutPaymentStatusResponse_Invoice> {
  /**
   * Required. The name of the invoice to pay. Ex: "hotel-reservations/qlwkdml3d/invoices/wvernlyneiv".
   *
   * @generated from field: repeated shackle.common.InvoiceSection section = 1;
   */
  section: InvoiceSection[] = [];

  /**
   * @generated from field: shackle.common.Money total = 2;
   */
  total?: Money;

  constructor(data?: PartialMessage<GetCheckoutPaymentStatusResponse_Invoice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.GetCheckoutPaymentStatusResponse.Invoice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "section", kind: "message", T: InvoiceSection, repeated: true },
    { no: 2, name: "total", kind: "message", T: Money },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCheckoutPaymentStatusResponse_Invoice {
    return new GetCheckoutPaymentStatusResponse_Invoice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCheckoutPaymentStatusResponse_Invoice {
    return new GetCheckoutPaymentStatusResponse_Invoice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCheckoutPaymentStatusResponse_Invoice {
    return new GetCheckoutPaymentStatusResponse_Invoice().fromJsonString(jsonString, options);
  }

  static equals(a: GetCheckoutPaymentStatusResponse_Invoice | PlainMessage<GetCheckoutPaymentStatusResponse_Invoice> | undefined, b: GetCheckoutPaymentStatusResponse_Invoice | PlainMessage<GetCheckoutPaymentStatusResponse_Invoice> | undefined): boolean {
    return proto3.util.equals(GetCheckoutPaymentStatusResponse_Invoice, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.CheckDeviceIdStatusRequest
 */
export class CheckDeviceIdStatusRequest extends Message<CheckDeviceIdStatusRequest> {
  /**
   * DeviceId to check status ex: "dPQTT".
   *
   * @generated from field: string device_id = 1;
   */
  deviceId = "";

  constructor(data?: PartialMessage<CheckDeviceIdStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.CheckDeviceIdStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckDeviceIdStatusRequest {
    return new CheckDeviceIdStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckDeviceIdStatusRequest {
    return new CheckDeviceIdStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckDeviceIdStatusRequest {
    return new CheckDeviceIdStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckDeviceIdStatusRequest | PlainMessage<CheckDeviceIdStatusRequest> | undefined, b: CheckDeviceIdStatusRequest | PlainMessage<CheckDeviceIdStatusRequest> | undefined): boolean {
    return proto3.util.equals(CheckDeviceIdStatusRequest, a, b);
  }
}

/**
 * @generated from message shackle.backend.v1.CheckDeviceIdStatusResponse
 */
export class CheckDeviceIdStatusResponse extends Message<CheckDeviceIdStatusResponse> {
  /**
   * DeviceId which is checked ex: "dPQTT".
   *
   * @generated from field: string device_id = 1;
   */
  deviceId = "";

  /**
   * status of device id
   *
   * @generated from field: shackle.backend.v1.DeviceIdStatus device_id_status = 2;
   */
  deviceIdStatus = DeviceIdStatus.UNKNOWN_STATUS;

  constructor(data?: PartialMessage<CheckDeviceIdStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.backend.v1.CheckDeviceIdStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "device_id_status", kind: "enum", T: proto3.getEnumType(DeviceIdStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckDeviceIdStatusResponse {
    return new CheckDeviceIdStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckDeviceIdStatusResponse {
    return new CheckDeviceIdStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckDeviceIdStatusResponse {
    return new CheckDeviceIdStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckDeviceIdStatusResponse | PlainMessage<CheckDeviceIdStatusResponse> | undefined, b: CheckDeviceIdStatusResponse | PlainMessage<CheckDeviceIdStatusResponse> | undefined): boolean {
    return proto3.util.equals(CheckDeviceIdStatusResponse, a, b);
  }
}

