const resetClientStorage = async (confirmation = false, beforeRedirect?: Function) => {
  if (!confirmation || window.confirm('This will completely clear all of your information, and you would need to start the check-in process again. Are you sure you want to continue?')) {

    Object.keys(localStorage).forEach((key: string) => {
      if (key.startsWith('formkit-') || key.startsWith('shackle-') || ['reservationName', 'reservationStage'].includes(key)) {
        localStorage.removeItem(key)
      }
    })
    useWebsiteStore().$reset()
    useCheckInStore().$reset()

    if (beforeRedirect) await beforeRedirect()

    await navigateTo("/")
  }
}

export {
  resetClientStorage
}