// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file shackle/backend/common.proto (package shackle.common, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { TimeOfDay } from "../../google/type/timeofday_pb.js";
import { BookingSource, Money, PaymentGateway, Region } from "../common_pb.js";
import { Date } from "../../google/type/date_pb.js";

/**
 * @generated from enum shackle.common.InformationType
 */
export enum InformationType {
  /**
   * @generated from enum value: INFO = 0;
   */
  INFO = 0,

  /**
   * @generated from enum value: WARNING = 1;
   */
  WARNING = 1,

  /**
   * @generated from enum value: ERROR = 2;
   */
  ERROR = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InformationType)
proto3.util.setEnumType(InformationType, "shackle.common.InformationType", [
  { no: 0, name: "INFO" },
  { no: 1, name: "WARNING" },
  { no: 2, name: "ERROR" },
]);

/**
 * @generated from enum shackle.common.InformationTarget
 */
export enum InformationTarget {
  /**
   * @generated from enum value: APP = 0;
   */
  APP = 0,

  /**
   * @generated from enum value: BACKOFFICE = 1;
   */
  BACKOFFICE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(InformationTarget)
proto3.util.setEnumType(InformationTarget, "shackle.common.InformationTarget", [
  { no: 0, name: "APP" },
  { no: 1, name: "BACKOFFICE" },
]);

/**
 * @generated from message shackle.common.Property
 */
export class Property extends Message<Property> {
  /**
   * Required. Name of the property, ex: "properties/qwdlqw123d".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Required. Display name of the property.
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * T&C of the property.
   *
   * @generated from field: string terms_and_conditions = 3;
   */
  termsAndConditions = "";

  /**
   * Required. Address of the property.
   *
   * @generated from field: shackle.common.Property.Address address = 4;
   */
  address?: Property_Address;

  /**
   * Required. Type of the lock system that is installed at the property.
   *
   * @generated from field: shackle.common.Property.LockType lock_type = 5;
   */
  lockType = Property_LockType.LOCK_TYPE_UNSPECIFIED;

  /**
   * Required. Marketing description of the property.
   *
   * @generated from field: string marketing_description = 6 [deprecated = true];
   * @deprecated
   */
  marketingDescription = "";

  /**
   * Required. Contact details of the property.
   *
   * @generated from field: shackle.common.Property.ContactDetails contact_details = 7;
   */
  contactDetails?: Property_ContactDetails;

  /**
   * Required. Contact details of the property.
   *
   * @generated from field: shackle.common.Property.Media media = 8;
   */
  media?: Property_Media;

  /**
   * Required. Information about the hotel.
   *
   * @generated from field: repeated shackle.common.Property.Section information = 9;
   */
  information: Property_Section[] = [];

  /**
   * IANA time zone of the property.
   * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   *
   * @generated from field: string time_zone = 10;
   */
  timeZone = "";

  /**
   * Required.
   *
   * @generated from field: google.type.TimeOfDay check_in_time = 11;
   */
  checkInTime?: TimeOfDay;

  /**
   * Required.
   *
   * @generated from field: google.type.TimeOfDay check_out_time = 12;
   */
  checkOutTime?: TimeOfDay;

  /**
   * Required
   *
   * @generated from field: shackle.common.Region region = 13;
   */
  region = Region.eu;

  /**
   * Optional.
   *
   * @generated from field: shackle.common.Property.InstagramProfile instagram_profile = 14;
   */
  instagramProfile?: Property_InstagramProfile;

  /**
   * @generated from field: bool is_manual_checkin_verification_required = 15;
   */
  isManualCheckinVerificationRequired = false;

  /**
   * @generated from field: shackle.common.PaymentGateway payment_gateway = 16;
   */
  paymentGateway = PaymentGateway.NONE;

  /**
   * @generated from field: repeated shackle.common.BookingSourceConfiguration booking_sources = 17;
   */
  bookingSources: BookingSourceConfiguration[] = [];

  /**
   * @generated from field: string id = 18;
   */
  id = "";

  /**
   * @generated from field: bool is_food_and_beverage_enabled = 19;
   */
  isFoodAndBeverageEnabled = false;

  /**
   * @generated from field: bool is_upsell_enabled = 20;
   */
  isUpsellEnabled = false;

  /**
   * @generated from field: shackle.common.Property.Benefits benefits = 21;
   */
  benefits?: Property_Benefits;

  /**
   * Lock details. Only the corresponding field with lock type will be set.
   *
   * @generated from oneof shackle.common.Property.lock_details
   */
  lockDetails: {
    /**
     * @generated from field: shackle.common.Property.AssaAbloyDetails assa_abloy_details = 32;
     */
    value: Property_AssaAbloyDetails;
    case: "assaAbloyDetails";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Property>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "terms_and_conditions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "message", T: Property_Address },
    { no: 5, name: "lock_type", kind: "enum", T: proto3.getEnumType(Property_LockType) },
    { no: 6, name: "marketing_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "contact_details", kind: "message", T: Property_ContactDetails },
    { no: 8, name: "media", kind: "message", T: Property_Media },
    { no: 9, name: "information", kind: "message", T: Property_Section, repeated: true },
    { no: 10, name: "time_zone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "check_in_time", kind: "message", T: TimeOfDay },
    { no: 12, name: "check_out_time", kind: "message", T: TimeOfDay },
    { no: 13, name: "region", kind: "enum", T: proto3.getEnumType(Region) },
    { no: 14, name: "instagram_profile", kind: "message", T: Property_InstagramProfile },
    { no: 15, name: "is_manual_checkin_verification_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "payment_gateway", kind: "enum", T: proto3.getEnumType(PaymentGateway) },
    { no: 17, name: "booking_sources", kind: "message", T: BookingSourceConfiguration, repeated: true },
    { no: 18, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "is_food_and_beverage_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "is_upsell_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "benefits", kind: "message", T: Property_Benefits },
    { no: 32, name: "assa_abloy_details", kind: "message", T: Property_AssaAbloyDetails, oneof: "lock_details" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property {
    return new Property().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property {
    return new Property().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property {
    return new Property().fromJsonString(jsonString, options);
  }

  static equals(a: Property | PlainMessage<Property> | undefined, b: Property | PlainMessage<Property> | undefined): boolean {
    return proto3.util.equals(Property, a, b);
  }
}

/**
 * @generated from enum shackle.common.Property.LockType
 */
export enum Property_LockType {
  /**
   * When a new lock type is added which a client doesn't know, protobuf will default to this
   * value. Use this to check whether a client must be updated.
   *
   * @generated from enum value: LOCK_TYPE_UNSPECIFIED = 0;
   */
  LOCK_TYPE_UNSPECIFIED = 0,

  /**
   * Locks provided by Dormakaba. Backend automatically provisions keys for a registered device
   * when the reservation is under a property with this lock type.
   *
   * @generated from enum value: DORMAKABA = 1;
   */
  DORMAKABA = 1,

  /**
   * @generated from enum value: ASSA_ABLOY = 2;
   */
  ASSA_ABLOY = 2,

  /**
   * No lock provider, physical keys should be requested at front office.
   *
   * @generated from enum value: PHYSICAL_KEY = 3;
   */
  PHYSICAL_KEY = 3,

  /**
   * @generated from enum value: SALTO = 4;
   */
  SALTO = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Property_LockType)
proto3.util.setEnumType(Property_LockType, "shackle.common.Property.LockType", [
  { no: 0, name: "LOCK_TYPE_UNSPECIFIED" },
  { no: 1, name: "DORMAKABA" },
  { no: 2, name: "ASSA_ABLOY" },
  { no: 3, name: "PHYSICAL_KEY" },
  { no: 4, name: "SALTO" },
]);

/**
 * @generated from message shackle.common.Property.Address
 */
export class Property_Address extends Message<Property_Address> {
  /**
   * Required. Address line of the property. Ex: "38-51 Bedford Way"
   *
   * @generated from field: string address_line = 1;
   */
  addressLine = "";

  /**
   * Required. City of the property. Ex: "London".
   *
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * Required. Zip code of the property. Ex: "WC1H 0DG"
   *
   * @generated from field: string zip_code = 3;
   */
  zipCode = "";

  /**
   * Required. ISO-3166 alpha-2 country code of the property. Ex: "GB".
   *
   * @generated from field: string country_code = 4;
   */
  countryCode = "";

  constructor(data?: PartialMessage<Property_Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_line", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_Address {
    return new Property_Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_Address {
    return new Property_Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_Address {
    return new Property_Address().fromJsonString(jsonString, options);
  }

  static equals(a: Property_Address | PlainMessage<Property_Address> | undefined, b: Property_Address | PlainMessage<Property_Address> | undefined): boolean {
    return proto3.util.equals(Property_Address, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.ContactDetails
 */
export class Property_ContactDetails extends Message<Property_ContactDetails> {
  /**
   * Required. Phone number of the property, in ITU E.164 format. Ex: "+41446681800".
   * See: https://github.com/google/libphonenumber for examples.
   *
   * @generated from field: string telephone = 1;
   */
  telephone = "";

  /**
   * Required. E-mail address of the hotel. Validated as sensible subset of RFC2822. Ex: "beachresort@hotel.gero".
   *
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * Required. Link to the website of the property (or hotel chain). Ex: "https://hotel.gero/beachresort".
   *
   * @generated from field: string web = 3;
   */
  web = "";

  constructor(data?: PartialMessage<Property_ContactDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.ContactDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "telephone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "web", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_ContactDetails {
    return new Property_ContactDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_ContactDetails {
    return new Property_ContactDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_ContactDetails {
    return new Property_ContactDetails().fromJsonString(jsonString, options);
  }

  static equals(a: Property_ContactDetails | PlainMessage<Property_ContactDetails> | undefined, b: Property_ContactDetails | PlainMessage<Property_ContactDetails> | undefined): boolean {
    return proto3.util.equals(Property_ContactDetails, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.Media
 */
export class Property_Media extends Message<Property_Media> {
  /**
   * Required. Link to the banner image of the hotel (visible on the stay page of the app, on top).
   *
   * @generated from field: string banner_image_link = 1;
   */
  bannerImageLink = "";

  /**
   * Required. Link to the logo image of the hotel (visible on the splash page of the app).
   *
   * @generated from field: string logo_image_link = 2;
   */
  logoImageLink = "";

  /**
   * Required. Link to the thumbnail image of the hotel (visible on listings and search results).
   *
   * @generated from field: string thumbnail_image_link = 3;
   */
  thumbnailImageLink = "";

  /**
   * List of image links displaying the property. Ex: "https://gero.hotel/magic.jpg".
   *
   * @generated from field: repeated string gallery_image_link = 6;
   */
  galleryImageLink: string[] = [];

  constructor(data?: PartialMessage<Property_Media>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.Media";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "banner_image_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "logo_image_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "thumbnail_image_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "gallery_image_link", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_Media {
    return new Property_Media().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_Media {
    return new Property_Media().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_Media {
    return new Property_Media().fromJsonString(jsonString, options);
  }

  static equals(a: Property_Media | PlainMessage<Property_Media> | undefined, b: Property_Media | PlainMessage<Property_Media> | undefined): boolean {
    return proto3.util.equals(Property_Media, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.Section
 */
export class Property_Section extends Message<Property_Section> {
  /**
   * Required. Title of the section
   *
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * Required. Paragraphs of the section.
   *
   * @generated from field: repeated string paragraph = 2;
   */
  paragraph: string[] = [];

  constructor(data?: PartialMessage<Property_Section>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.Section";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paragraph", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_Section {
    return new Property_Section().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_Section {
    return new Property_Section().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_Section {
    return new Property_Section().fromJsonString(jsonString, options);
  }

  static equals(a: Property_Section | PlainMessage<Property_Section> | undefined, b: Property_Section | PlainMessage<Property_Section> | undefined): boolean {
    return proto3.util.equals(Property_Section, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.AssaAbloyDetails
 */
export class Property_AssaAbloyDetails extends Message<Property_AssaAbloyDetails> {
  /**
   *
   * Lock Service Code is a numerical value that forms part of the Bluetooth service advertisement from Assa Abloy Hospitality (AAH) locks.
   * Each lock can only advertise a single Lock Service Code The value typically matches a hotel chain, or equivalent business unit,
   * thus segregating AAH locks into various channels. All AAH locks at any given site will be configured to advertise the same value.
   *
   * This is the same as the UUID setting in Visionline. It will be 1 for testing purposes.
   *
   * @generated from field: int32 lock_service_code = 1;
   */
  lockServiceCode = 0;

  constructor(data?: PartialMessage<Property_AssaAbloyDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.AssaAbloyDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lock_service_code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_AssaAbloyDetails {
    return new Property_AssaAbloyDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_AssaAbloyDetails {
    return new Property_AssaAbloyDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_AssaAbloyDetails {
    return new Property_AssaAbloyDetails().fromJsonString(jsonString, options);
  }

  static equals(a: Property_AssaAbloyDetails | PlainMessage<Property_AssaAbloyDetails> | undefined, b: Property_AssaAbloyDetails | PlainMessage<Property_AssaAbloyDetails> | undefined): boolean {
    return proto3.util.equals(Property_AssaAbloyDetails, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.InstagramProfile
 */
export class Property_InstagramProfile extends Message<Property_InstagramProfile> {
  /**
   * Required.
   *
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * Required.
   *
   * @generated from field: string profile_url = 2;
   */
  profileUrl = "";

  /**
   * List of instagram images.
   *
   * @generated from field: repeated shackle.common.Property.InstagramProfile.InstagramImageLink instagram_image_link = 3;
   */
  instagramImageLink: Property_InstagramProfile_InstagramImageLink[] = [];

  constructor(data?: PartialMessage<Property_InstagramProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.InstagramProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instagram_image_link", kind: "message", T: Property_InstagramProfile_InstagramImageLink, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_InstagramProfile {
    return new Property_InstagramProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_InstagramProfile {
    return new Property_InstagramProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_InstagramProfile {
    return new Property_InstagramProfile().fromJsonString(jsonString, options);
  }

  static equals(a: Property_InstagramProfile | PlainMessage<Property_InstagramProfile> | undefined, b: Property_InstagramProfile | PlainMessage<Property_InstagramProfile> | undefined): boolean {
    return proto3.util.equals(Property_InstagramProfile, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.InstagramProfile.InstagramImageLink
 */
export class Property_InstagramProfile_InstagramImageLink extends Message<Property_InstagramProfile_InstagramImageLink> {
  /**
   * Required. Link to the image.
   *
   * @generated from field: string image_link = 1;
   */
  imageLink = "";

  /**
   * Required. Link to the instagram page.
   *
   * @generated from field: string instagram_link = 2;
   */
  instagramLink = "";

  constructor(data?: PartialMessage<Property_InstagramProfile_InstagramImageLink>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.InstagramProfile.InstagramImageLink";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "instagram_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_InstagramProfile_InstagramImageLink {
    return new Property_InstagramProfile_InstagramImageLink().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_InstagramProfile_InstagramImageLink {
    return new Property_InstagramProfile_InstagramImageLink().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_InstagramProfile_InstagramImageLink {
    return new Property_InstagramProfile_InstagramImageLink().fromJsonString(jsonString, options);
  }

  static equals(a: Property_InstagramProfile_InstagramImageLink | PlainMessage<Property_InstagramProfile_InstagramImageLink> | undefined, b: Property_InstagramProfile_InstagramImageLink | PlainMessage<Property_InstagramProfile_InstagramImageLink> | undefined): boolean {
    return proto3.util.equals(Property_InstagramProfile_InstagramImageLink, a, b);
  }
}

/**
 * @generated from message shackle.common.Property.Benefits
 */
export class Property_Benefits extends Message<Property_Benefits> {
  /**
   * @generated from field: repeated string hotel_benefit = 1;
   */
  hotelBenefit: string[] = [];

  /**
   * @generated from field: repeated string shackle_benefit = 2;
   */
  shackleBenefit: string[] = [];

  constructor(data?: PartialMessage<Property_Benefits>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Property.Benefits";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hotel_benefit", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "shackle_benefit", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property_Benefits {
    return new Property_Benefits().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property_Benefits {
    return new Property_Benefits().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property_Benefits {
    return new Property_Benefits().fromJsonString(jsonString, options);
  }

  static equals(a: Property_Benefits | PlainMessage<Property_Benefits> | undefined, b: Property_Benefits | PlainMessage<Property_Benefits> | undefined): boolean {
    return proto3.util.equals(Property_Benefits, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField
 */
export class FormField extends Message<FormField> {
  /**
   * !Important: With regCardV2 this name structure is changed to "form/form-id-123/field/addresses.home.address_line_one".
   * Required. Name of the field. Ex: "properties/lqkwdmlqwd/registration-form/versions/1/fields/addresses.home.address_line_one".
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Required. True if this field must be filled in. Can be left empty when false.
   *
   * @generated from field: bool mandatory = 2;
   */
  mandatory = false;

  /**
   * Required. Field identifier, ex: "addresses.home.address_line_one", "first_name".
   * Values used for reference in validation and in the sent form. Identifiers imply semantics,
   * but do not rely on them in code. They are liable to change.
   *
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * Required. Display name of the field. Ex: "Country of origin".
   *
   * @generated from field: string display_name = 4;
   */
  displayName = "";

  /**
   * Required. Immutable. This is separated across types (as opposed to an enum and a fixed string
   * value) because later we may want to send payloads of different types. An image (think signature
   * or passport photo) would be convenient to send in byte, not string.
   *
   * @generated from oneof shackle.common.FormField.field
   */
  field: {
    /**
     * @generated from field: shackle.common.FormField.Text text_field = 5;
     */
    value: FormField_Text;
    case: "textField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Country country_field = 6;
     */
    value: FormField_Country;
    case: "countryField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Select select_field = 7;
     */
    value: FormField_Select;
    case: "selectField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Date date_field = 8;
     */
    value: FormField_Date;
    case: "dateField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Checkbox checkbox_field = 9;
     */
    value: FormField_Checkbox;
    case: "checkboxField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Time time_field = 10;
     */
    value: FormField_Time;
    case: "timeField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Phone phone_field = 11;
     */
    value: FormField_Phone;
    case: "phoneField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Image image_field = 12;
     */
    value: FormField_Image;
    case: "imageField";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Nationality nationality_field = 14;
     */
    value: FormField_Nationality;
    case: "nationalityField";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Returns the keyboard type for the field. Can be used to show arabic keyboard, email keyboard etc.
   *
   * @generated from field: string input_type = 20;
   */
  inputType = "";

  constructor(data?: PartialMessage<FormField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mandatory", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "text_field", kind: "message", T: FormField_Text, oneof: "field" },
    { no: 6, name: "country_field", kind: "message", T: FormField_Country, oneof: "field" },
    { no: 7, name: "select_field", kind: "message", T: FormField_Select, oneof: "field" },
    { no: 8, name: "date_field", kind: "message", T: FormField_Date, oneof: "field" },
    { no: 9, name: "checkbox_field", kind: "message", T: FormField_Checkbox, oneof: "field" },
    { no: 10, name: "time_field", kind: "message", T: FormField_Time, oneof: "field" },
    { no: 11, name: "phone_field", kind: "message", T: FormField_Phone, oneof: "field" },
    { no: 12, name: "image_field", kind: "message", T: FormField_Image, oneof: "field" },
    { no: 14, name: "nationality_field", kind: "message", T: FormField_Nationality, oneof: "field" },
    { no: 20, name: "input_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField {
    return new FormField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField {
    return new FormField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField {
    return new FormField().fromJsonString(jsonString, options);
  }

  static equals(a: FormField | PlainMessage<FormField> | undefined, b: FormField | PlainMessage<FormField> | undefined): boolean {
    return proto3.util.equals(FormField, a, b);
  }
}

/**
 * @generated from enum shackle.common.FormField.ImageStatus
 */
export enum FormField_ImageStatus {
  /**
   * @generated from enum value: WAITING_FOR_UPLOAD = 0;
   */
  WAITING_FOR_UPLOAD = 0,

  /**
   * @generated from enum value: UPLOADED = 1;
   */
  UPLOADED = 1,

  /**
   * @generated from enum value: REPLACED = 2;
   */
  REPLACED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(FormField_ImageStatus)
proto3.util.setEnumType(FormField_ImageStatus, "shackle.common.FormField.ImageStatus", [
  { no: 0, name: "WAITING_FOR_UPLOAD" },
  { no: 1, name: "UPLOADED" },
  { no: 2, name: "REPLACED" },
]);

/**
 * Option in a select.
 *
 * @generated from message shackle.common.FormField.SelectOption
 */
export class FormField_SelectOption extends Message<FormField_SelectOption> {
  /**
   * Show this to the user in the select.
   *
   * @generated from field: string display_name = 1;
   */
  displayName = "";

  /**
   * Use this for the value field of the field.
   *
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<FormField_SelectOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.SelectOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_SelectOption {
    return new FormField_SelectOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_SelectOption {
    return new FormField_SelectOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_SelectOption {
    return new FormField_SelectOption().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_SelectOption | PlainMessage<FormField_SelectOption> | undefined, b: FormField_SelectOption | PlainMessage<FormField_SelectOption> | undefined): boolean {
    return proto3.util.equals(FormField_SelectOption, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Text
 */
export class FormField_Text extends Message<FormField_Text> {
  /**
   * Value of the text field.
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * Optional field which is used to return regex which is used to validate provided text value
   * Does not need to be provided in the request
   *
   * @generated from field: string validation_regex = 2;
   */
  validationRegex = "";

  constructor(data?: PartialMessage<FormField_Text>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Text";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "validation_regex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Text {
    return new FormField_Text().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Text {
    return new FormField_Text().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Text {
    return new FormField_Text().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Text | PlainMessage<FormField_Text> | undefined, b: FormField_Text | PlainMessage<FormField_Text> | undefined): boolean {
    return proto3.util.equals(FormField_Text, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Country
 */
export class FormField_Country extends Message<FormField_Country> {
  /**
   * ISO-3166 alpha-2 country code. Value of the field.
   *
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<FormField_Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Country {
    return new FormField_Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Country {
    return new FormField_Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Country {
    return new FormField_Country().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Country | PlainMessage<FormField_Country> | undefined, b: FormField_Country | PlainMessage<FormField_Country> | undefined): boolean {
    return proto3.util.equals(FormField_Country, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Nationality
 */
export class FormField_Nationality extends Message<FormField_Nationality> {
  /**
   * ISO-3166 alpha-2 country code. Value of the field.
   *
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<FormField_Nationality>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Nationality";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Nationality {
    return new FormField_Nationality().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Nationality {
    return new FormField_Nationality().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Nationality {
    return new FormField_Nationality().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Nationality | PlainMessage<FormField_Nationality> | undefined, b: FormField_Nationality | PlainMessage<FormField_Nationality> | undefined): boolean {
    return proto3.util.equals(FormField_Nationality, a, b);
  }
}

/**
 * Single value select field.
 *
 * @generated from message shackle.common.FormField.Select
 */
export class FormField_Select extends Message<FormField_Select> {
  /**
   * Value of the selected option.
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * Possible values to choose from.
   *
   * @generated from field: repeated shackle.common.FormField.SelectOption option = 2;
   */
  option: FormField_SelectOption[] = [];

  constructor(data?: PartialMessage<FormField_Select>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Select";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "option", kind: "message", T: FormField_SelectOption, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Select {
    return new FormField_Select().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Select {
    return new FormField_Select().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Select {
    return new FormField_Select().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Select | PlainMessage<FormField_Select> | undefined, b: FormField_Select | PlainMessage<FormField_Select> | undefined): boolean {
    return proto3.util.equals(FormField_Select, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Date
 */
export class FormField_Date extends Message<FormField_Date> {
  /**
   * Value for date field
   *
   * @generated from field: google.type.Date value = 1;
   */
  value?: Date;

  /**
   * @generated from oneof shackle.common.FormField.Date.type
   */
  type: {
    /**
     * @generated from field: shackle.common.FormField.Date.Range range = 2;
     */
    value: FormField_Date_Range;
    case: "range";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Date.PastDates past_dates = 3;
     */
    value: FormField_Date_PastDates;
    case: "pastDates";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Date.PastDatesAndCurrentDate past_dates_and_current_date = 4;
     */
    value: FormField_Date_PastDatesAndCurrentDate;
    case: "pastDatesAndCurrentDate";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Date.FutureDates future_dates = 5;
     */
    value: FormField_Date_FutureDates;
    case: "futureDates";
  } | {
    /**
     * @generated from field: shackle.common.FormField.Date.FutureDatesAndCurrentDate future_dates_and_current_date = 6;
     */
    value: FormField_Date_FutureDatesAndCurrentDate;
    case: "futureDatesAndCurrentDate";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FormField_Date>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Date";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "message", T: Date },
    { no: 2, name: "range", kind: "message", T: FormField_Date_Range, oneof: "type" },
    { no: 3, name: "past_dates", kind: "message", T: FormField_Date_PastDates, oneof: "type" },
    { no: 4, name: "past_dates_and_current_date", kind: "message", T: FormField_Date_PastDatesAndCurrentDate, oneof: "type" },
    { no: 5, name: "future_dates", kind: "message", T: FormField_Date_FutureDates, oneof: "type" },
    { no: 6, name: "future_dates_and_current_date", kind: "message", T: FormField_Date_FutureDatesAndCurrentDate, oneof: "type" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Date {
    return new FormField_Date().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Date {
    return new FormField_Date().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Date {
    return new FormField_Date().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Date | PlainMessage<FormField_Date> | undefined, b: FormField_Date | PlainMessage<FormField_Date> | undefined): boolean {
    return proto3.util.equals(FormField_Date, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Date.Range
 */
export class FormField_Date_Range extends Message<FormField_Date_Range> {
  /**
   * Value should be higher than equal to from.
   *
   * @generated from field: google.type.Date from = 1;
   */
  from?: Date;

  /**
   * Value should be less than equal to to.
   *
   * @generated from field: google.type.Date to = 2;
   */
  to?: Date;

  constructor(data?: PartialMessage<FormField_Date_Range>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Date.Range";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "message", T: Date },
    { no: 2, name: "to", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Date_Range {
    return new FormField_Date_Range().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Date_Range {
    return new FormField_Date_Range().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Date_Range {
    return new FormField_Date_Range().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Date_Range | PlainMessage<FormField_Date_Range> | undefined, b: FormField_Date_Range | PlainMessage<FormField_Date_Range> | undefined): boolean {
    return proto3.util.equals(FormField_Date_Range, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Date.PastDates
 */
export class FormField_Date_PastDates extends Message<FormField_Date_PastDates> {
  /**
   * Value should be higher than equal to from.
   *
   * @generated from field: google.type.Date from = 1;
   */
  from?: Date;

  constructor(data?: PartialMessage<FormField_Date_PastDates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Date.PastDates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Date_PastDates {
    return new FormField_Date_PastDates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Date_PastDates {
    return new FormField_Date_PastDates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Date_PastDates {
    return new FormField_Date_PastDates().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Date_PastDates | PlainMessage<FormField_Date_PastDates> | undefined, b: FormField_Date_PastDates | PlainMessage<FormField_Date_PastDates> | undefined): boolean {
    return proto3.util.equals(FormField_Date_PastDates, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Date.PastDatesAndCurrentDate
 */
export class FormField_Date_PastDatesAndCurrentDate extends Message<FormField_Date_PastDatesAndCurrentDate> {
  /**
   * Value should be higher than equal to from.
   *
   * @generated from field: google.type.Date from = 1;
   */
  from?: Date;

  constructor(data?: PartialMessage<FormField_Date_PastDatesAndCurrentDate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Date.PastDatesAndCurrentDate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Date_PastDatesAndCurrentDate {
    return new FormField_Date_PastDatesAndCurrentDate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Date_PastDatesAndCurrentDate {
    return new FormField_Date_PastDatesAndCurrentDate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Date_PastDatesAndCurrentDate {
    return new FormField_Date_PastDatesAndCurrentDate().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Date_PastDatesAndCurrentDate | PlainMessage<FormField_Date_PastDatesAndCurrentDate> | undefined, b: FormField_Date_PastDatesAndCurrentDate | PlainMessage<FormField_Date_PastDatesAndCurrentDate> | undefined): boolean {
    return proto3.util.equals(FormField_Date_PastDatesAndCurrentDate, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Date.FutureDates
 */
export class FormField_Date_FutureDates extends Message<FormField_Date_FutureDates> {
  /**
   * Value should be less than equal to to.
   *
   * @generated from field: google.type.Date to = 1;
   */
  to?: Date;

  constructor(data?: PartialMessage<FormField_Date_FutureDates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Date.FutureDates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "to", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Date_FutureDates {
    return new FormField_Date_FutureDates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Date_FutureDates {
    return new FormField_Date_FutureDates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Date_FutureDates {
    return new FormField_Date_FutureDates().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Date_FutureDates | PlainMessage<FormField_Date_FutureDates> | undefined, b: FormField_Date_FutureDates | PlainMessage<FormField_Date_FutureDates> | undefined): boolean {
    return proto3.util.equals(FormField_Date_FutureDates, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Date.FutureDatesAndCurrentDate
 */
export class FormField_Date_FutureDatesAndCurrentDate extends Message<FormField_Date_FutureDatesAndCurrentDate> {
  /**
   * Value should be less than equal to to.
   *
   * @generated from field: google.type.Date to = 1;
   */
  to?: Date;

  constructor(data?: PartialMessage<FormField_Date_FutureDatesAndCurrentDate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Date.FutureDatesAndCurrentDate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "to", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Date_FutureDatesAndCurrentDate {
    return new FormField_Date_FutureDatesAndCurrentDate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Date_FutureDatesAndCurrentDate {
    return new FormField_Date_FutureDatesAndCurrentDate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Date_FutureDatesAndCurrentDate {
    return new FormField_Date_FutureDatesAndCurrentDate().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Date_FutureDatesAndCurrentDate | PlainMessage<FormField_Date_FutureDatesAndCurrentDate> | undefined, b: FormField_Date_FutureDatesAndCurrentDate | PlainMessage<FormField_Date_FutureDatesAndCurrentDate> | undefined): boolean {
    return proto3.util.equals(FormField_Date_FutureDatesAndCurrentDate, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Checkbox
 */
export class FormField_Checkbox extends Message<FormField_Checkbox> {
  /**
   * @generated from field: bool value = 1;
   */
  value = false;

  constructor(data?: PartialMessage<FormField_Checkbox>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Checkbox";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Checkbox {
    return new FormField_Checkbox().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Checkbox {
    return new FormField_Checkbox().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Checkbox {
    return new FormField_Checkbox().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Checkbox | PlainMessage<FormField_Checkbox> | undefined, b: FormField_Checkbox | PlainMessage<FormField_Checkbox> | undefined): boolean {
    return proto3.util.equals(FormField_Checkbox, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Time
 */
export class FormField_Time extends Message<FormField_Time> {
  /**
   * @generated from field: google.type.TimeOfDay value = 1;
   */
  value?: TimeOfDay;

  constructor(data?: PartialMessage<FormField_Time>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Time";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "message", T: TimeOfDay },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Time {
    return new FormField_Time().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Time {
    return new FormField_Time().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Time {
    return new FormField_Time().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Time | PlainMessage<FormField_Time> | undefined, b: FormField_Time | PlainMessage<FormField_Time> | undefined): boolean {
    return proto3.util.equals(FormField_Time, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Phone
 */
export class FormField_Phone extends Message<FormField_Phone> {
  /**
   * Required. The E164 country code of the phone number. Example: `31`
   *
   * @generated from field: string country_code = 1;
   */
  countryCode = "";

  /**
   * Just phone number without country code
   *
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<FormField_Phone>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Phone";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Phone {
    return new FormField_Phone().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Phone {
    return new FormField_Phone().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Phone {
    return new FormField_Phone().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Phone | PlainMessage<FormField_Phone> | undefined, b: FormField_Phone | PlainMessage<FormField_Phone> | undefined): boolean {
    return proto3.util.equals(FormField_Phone, a, b);
  }
}

/**
 * @generated from message shackle.common.FormField.Image
 */
export class FormField_Image extends Message<FormField_Image> {
  /**
   * presigned URL to upload image or access to existing image
   *
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * Indicates the status of image. If it returned as uploaded, url should be used to get image.
   * If an image is uploaded, this need to be set as UPLOADED or REPLACED
   *
   * @generated from field: shackle.common.FormField.ImageStatus status = 2;
   */
  status = FormField_ImageStatus.WAITING_FOR_UPLOAD;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string scanning_label = 4;
   */
  scanningLabel = "";

  /**
   * @generated from field: string confirmation_label = 5;
   */
  confirmationLabel = "";

  constructor(data?: PartialMessage<FormField_Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormField.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(FormField_ImageStatus) },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "scanning_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "confirmation_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormField_Image {
    return new FormField_Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormField_Image {
    return new FormField_Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormField_Image {
    return new FormField_Image().fromJsonString(jsonString, options);
  }

  static equals(a: FormField_Image | PlainMessage<FormField_Image> | undefined, b: FormField_Image | PlainMessage<FormField_Image> | undefined): boolean {
    return proto3.util.equals(FormField_Image, a, b);
  }
}

/**
 * @generated from message shackle.common.FormSection
 */
export class FormSection extends Message<FormSection> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * Fields to be filled.
   *
   * @generated from field: repeated shackle.common.FormField field = 4;
   */
  field: FormField[] = [];

  constructor(data?: PartialMessage<FormSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.FormSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "field", kind: "message", T: FormField, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormSection {
    return new FormSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormSection {
    return new FormSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormSection {
    return new FormSection().fromJsonString(jsonString, options);
  }

  static equals(a: FormSection | PlainMessage<FormSection> | undefined, b: FormSection | PlainMessage<FormSection> | undefined): boolean {
    return proto3.util.equals(FormSection, a, b);
  }
}

/**
 * @generated from message shackle.common.InvoiceSection
 */
export class InvoiceSection extends Message<InvoiceSection> {
  /**
   * @generated from field: repeated shackle.common.InvoiceSection.Item item = 1;
   */
  item: InvoiceSection_Item[] = [];

  constructor(data?: PartialMessage<InvoiceSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.InvoiceSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "item", kind: "message", T: InvoiceSection_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InvoiceSection {
    return new InvoiceSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InvoiceSection {
    return new InvoiceSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InvoiceSection {
    return new InvoiceSection().fromJsonString(jsonString, options);
  }

  static equals(a: InvoiceSection | PlainMessage<InvoiceSection> | undefined, b: InvoiceSection | PlainMessage<InvoiceSection> | undefined): boolean {
    return proto3.util.equals(InvoiceSection, a, b);
  }
}

/**
 * @generated from message shackle.common.InvoiceSection.Item
 */
export class InvoiceSection_Item extends Message<InvoiceSection_Item> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: shackle.common.Money amount = 2;
   */
  amount?: Money;

  /**
   * @generated from field: google.type.Date date = 3;
   */
  date?: Date;

  /**
   * Expected to be always ge than 1
   *
   * @generated from field: int32 quantity = 4;
   */
  quantity = 0;

  /**
   * @generated from field: shackle.common.InvoiceSection.Item.ItemType type = 5;
   */
  type = InvoiceSection_Item_ItemType.PRIMARY;

  constructor(data?: PartialMessage<InvoiceSection_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.InvoiceSection.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "message", T: Money },
    { no: 3, name: "date", kind: "message", T: Date },
    { no: 4, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(InvoiceSection_Item_ItemType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InvoiceSection_Item {
    return new InvoiceSection_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InvoiceSection_Item {
    return new InvoiceSection_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InvoiceSection_Item {
    return new InvoiceSection_Item().fromJsonString(jsonString, options);
  }

  static equals(a: InvoiceSection_Item | PlainMessage<InvoiceSection_Item> | undefined, b: InvoiceSection_Item | PlainMessage<InvoiceSection_Item> | undefined): boolean {
    return proto3.util.equals(InvoiceSection_Item, a, b);
  }
}

/**
 * @generated from enum shackle.common.InvoiceSection.Item.ItemType
 */
export enum InvoiceSection_Item_ItemType {
  /**
   * @generated from enum value: PRIMARY = 0;
   */
  PRIMARY = 0,

  /**
   * @generated from enum value: SECONDARY = 1;
   */
  SECONDARY = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(InvoiceSection_Item_ItemType)
proto3.util.setEnumType(InvoiceSection_Item_ItemType, "shackle.common.InvoiceSection.Item.ItemType", [
  { no: 0, name: "PRIMARY" },
  { no: 1, name: "SECONDARY" },
]);

/**
 * @generated from message shackle.common.BookingSourceConfiguration
 */
export class BookingSourceConfiguration extends Message<BookingSourceConfiguration> {
  /**
   * @generated from field: shackle.common.BookingSource booking_source = 1;
   */
  bookingSource = BookingSource.UNKNOWN_BOOKING_SOURCE;

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: shackle.common.BookingSourceConfiguration.SearchForm search_form = 3;
   */
  searchForm?: BookingSourceConfiguration_SearchForm;

  /**
   * @generated from field: repeated shackle.common.FormSection form_section = 7;
   */
  formSection: FormSection[] = [];

  constructor(data?: PartialMessage<BookingSourceConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.BookingSourceConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "booking_source", kind: "enum", T: proto3.getEnumType(BookingSource) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "search_form", kind: "message", T: BookingSourceConfiguration_SearchForm },
    { no: 7, name: "form_section", kind: "message", T: FormSection, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookingSourceConfiguration {
    return new BookingSourceConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookingSourceConfiguration {
    return new BookingSourceConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookingSourceConfiguration {
    return new BookingSourceConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: BookingSourceConfiguration | PlainMessage<BookingSourceConfiguration> | undefined, b: BookingSourceConfiguration | PlainMessage<BookingSourceConfiguration> | undefined): boolean {
    return proto3.util.equals(BookingSourceConfiguration, a, b);
  }
}

/**
 * @generated from message shackle.common.BookingSourceConfiguration.SearchForm
 */
export class BookingSourceConfiguration_SearchForm extends Message<BookingSourceConfiguration_SearchForm> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: string description_key = 2;
   */
  descriptionKey = "";

  /**
   * @generated from field: repeated shackle.common.FormField field = 3;
   */
  field: FormField[] = [];

  constructor(data?: PartialMessage<BookingSourceConfiguration_SearchForm>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.BookingSourceConfiguration.SearchForm";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "field", kind: "message", T: FormField, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookingSourceConfiguration_SearchForm {
    return new BookingSourceConfiguration_SearchForm().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookingSourceConfiguration_SearchForm {
    return new BookingSourceConfiguration_SearchForm().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookingSourceConfiguration_SearchForm {
    return new BookingSourceConfiguration_SearchForm().fromJsonString(jsonString, options);
  }

  static equals(a: BookingSourceConfiguration_SearchForm | PlainMessage<BookingSourceConfiguration_SearchForm> | undefined, b: BookingSourceConfiguration_SearchForm | PlainMessage<BookingSourceConfiguration_SearchForm> | undefined): boolean {
    return proto3.util.equals(BookingSourceConfiguration_SearchForm, a, b);
  }
}

/**
 * @generated from message shackle.common.CardCheckoutTokenSource
 */
export class CardCheckoutTokenSource extends Message<CardCheckoutTokenSource> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<CardCheckoutTokenSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.CardCheckoutTokenSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardCheckoutTokenSource {
    return new CardCheckoutTokenSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardCheckoutTokenSource {
    return new CardCheckoutTokenSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardCheckoutTokenSource {
    return new CardCheckoutTokenSource().fromJsonString(jsonString, options);
  }

  static equals(a: CardCheckoutTokenSource | PlainMessage<CardCheckoutTokenSource> | undefined, b: CardCheckoutTokenSource | PlainMessage<CardCheckoutTokenSource> | undefined): boolean {
    return proto3.util.equals(CardCheckoutTokenSource, a, b);
  }
}

/**
 * @generated from message shackle.common.ApplePayCheckoutTokenSource
 */
export class ApplePayCheckoutTokenSource extends Message<ApplePayCheckoutTokenSource> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<ApplePayCheckoutTokenSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.ApplePayCheckoutTokenSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApplePayCheckoutTokenSource {
    return new ApplePayCheckoutTokenSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApplePayCheckoutTokenSource {
    return new ApplePayCheckoutTokenSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApplePayCheckoutTokenSource {
    return new ApplePayCheckoutTokenSource().fromJsonString(jsonString, options);
  }

  static equals(a: ApplePayCheckoutTokenSource | PlainMessage<ApplePayCheckoutTokenSource> | undefined, b: ApplePayCheckoutTokenSource | PlainMessage<ApplePayCheckoutTokenSource> | undefined): boolean {
    return proto3.util.equals(ApplePayCheckoutTokenSource, a, b);
  }
}

/**
 * @generated from message shackle.common.GooglePayCheckoutTokenSource
 */
export class GooglePayCheckoutTokenSource extends Message<GooglePayCheckoutTokenSource> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<GooglePayCheckoutTokenSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.GooglePayCheckoutTokenSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GooglePayCheckoutTokenSource {
    return new GooglePayCheckoutTokenSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GooglePayCheckoutTokenSource {
    return new GooglePayCheckoutTokenSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GooglePayCheckoutTokenSource {
    return new GooglePayCheckoutTokenSource().fromJsonString(jsonString, options);
  }

  static equals(a: GooglePayCheckoutTokenSource | PlainMessage<GooglePayCheckoutTokenSource> | undefined, b: GooglePayCheckoutTokenSource | PlainMessage<GooglePayCheckoutTokenSource> | undefined): boolean {
    return proto3.util.equals(GooglePayCheckoutTokenSource, a, b);
  }
}

/**
 * @generated from message shackle.common.ElavonTokenSource
 */
export class ElavonTokenSource extends Message<ElavonTokenSource> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * @generated from field: map<string, string> detail = 2;
   */
  detail: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ElavonTokenSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.ElavonTokenSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "detail", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ElavonTokenSource {
    return new ElavonTokenSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ElavonTokenSource {
    return new ElavonTokenSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ElavonTokenSource {
    return new ElavonTokenSource().fromJsonString(jsonString, options);
  }

  static equals(a: ElavonTokenSource | PlainMessage<ElavonTokenSource> | undefined, b: ElavonTokenSource | PlainMessage<ElavonTokenSource> | undefined): boolean {
    return proto3.util.equals(ElavonTokenSource, a, b);
  }
}

/**
 * @generated from message shackle.common.PaymentSource
 */
export class PaymentSource extends Message<PaymentSource> {
  /**
   * @generated from oneof shackle.common.PaymentSource.self
   */
  self: {
    /**
     * @generated from field: shackle.common.CardCheckoutTokenSource card_checkout_token_source = 1;
     */
    value: CardCheckoutTokenSource;
    case: "cardCheckoutTokenSource";
  } | {
    /**
     * @generated from field: shackle.common.ApplePayCheckoutTokenSource apple_pay_checkout_token_source = 2;
     */
    value: ApplePayCheckoutTokenSource;
    case: "applePayCheckoutTokenSource";
  } | {
    /**
     * @generated from field: shackle.common.GooglePayCheckoutTokenSource google_pay_checkout_token_source = 3;
     */
    value: GooglePayCheckoutTokenSource;
    case: "googlePayCheckoutTokenSource";
  } | {
    /**
     * @generated from field: shackle.common.ElavonTokenSource elavon_token_source = 4;
     */
    value: ElavonTokenSource;
    case: "elavonTokenSource";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PaymentSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.PaymentSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card_checkout_token_source", kind: "message", T: CardCheckoutTokenSource, oneof: "self" },
    { no: 2, name: "apple_pay_checkout_token_source", kind: "message", T: ApplePayCheckoutTokenSource, oneof: "self" },
    { no: 3, name: "google_pay_checkout_token_source", kind: "message", T: GooglePayCheckoutTokenSource, oneof: "self" },
    { no: 4, name: "elavon_token_source", kind: "message", T: ElavonTokenSource, oneof: "self" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentSource {
    return new PaymentSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentSource {
    return new PaymentSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentSource {
    return new PaymentSource().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentSource | PlainMessage<PaymentSource> | undefined, b: PaymentSource | PlainMessage<PaymentSource> | undefined): boolean {
    return proto3.util.equals(PaymentSource, a, b);
  }
}

