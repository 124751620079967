<script setup lang="ts">
const siteContainer = ref();
const { createUser } = useUserStore();

await callOnce(createUser);

provide("siteContainer", siteContainer);
</script>

<template>
    <div
        class="font-['Circular_Std'] bg-blend-darken transition-colors overflow-y-scroll bg-contain"
        ref="siteContainer"
    >
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<style>
@font-face {
    font-weight: 400;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
    font-weight: 600;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Bold.ttf") format("truetype");
}

@font-face {
    font-weight: 300;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Light.otf") format("opentype");
}

@font-face {
    font-weight: 500;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Medium.ttf") format("truetype");
}

a[href]:not([data-btn]) {
    @apply text-teal-500 underline underline-offset-2;
}

html {
    @apply bg-slate-50;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.app-container {
    background: url("/images/sq-bg@sm.jpg") center center;
    background-size: cover;
}
</style>
