import * as Sentry from "@sentry/nuxt";

const DEV_HOST_URL = "checkin.dev.exploreshackle.tech";

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.env.MODE === "test") return; // dont run when testing

  const isDev =
    import.meta.env.DEV === true || window.location.host === DEV_HOST_URL;
  const tracePropagationTargets = isDev
    ? ["localhost", "https://checkin.dev.exploreshackle.tech"]
    : ["https://checkin.exploreshackle.app"];

  const sampleRate = isDev ? 1.0 : 0.2;

  const environment = isDev ? "development" : "production";

  Sentry.init({
    environment,
    dsn: useRuntimeConfig().public.sentryDsn,
    integrations: [Sentry.replayIntegration(), Sentry.httpClientIntegration()],
    // Tracing
    tracesSampleRate: sampleRate,
    tracePropagationTargets,

    // Session Replay
    replaysSessionSampleRate: sampleRate,
    replaysOnErrorSampleRate: 1.0, // Always capture replays on error
  });

  const { userId } = useUserStore();
  const currentSentryId = Sentry.getCurrentScope().getUser()?.id;

  if (userId && !currentSentryId) {
    Sentry.setUser({ id: userId });
  }
});
