import { Date as ProtoDate } from "~/grpc/proto/google/type/date_pb"

const protoDateToInputString = (protoDate: ProtoDate): string => {
  if (!protoDate) throw new Error('date should not be undefined')
  const { year, month, day } = protoDate  
  const protoYearPadded = year.toString().padStart(4, '0')
  const protoMonthPadded = month.toString().padStart(2, '0')
  const protoDayPadded = day.toString().padStart(2, '0')
  return `${protoYearPadded}-${protoMonthPadded}-${protoDayPadded}`
}

const dateToProtoDate = (date: ProtoDate | string): ProtoDate => {
  if (!date) throw new Error('date should not be undefined')
  const protoDate = new ProtoDate();

  if (typeof date === 'string') {
    const [year, month, day] = date?.split('-')
    protoDate.year = parseInt(year)
    protoDate.month = parseInt(month)
    protoDate.day = parseInt(day)
  } else {
    protoDate.day = date.day 
    protoDate.month = date.month
    protoDate.year = date.year 
  }

  return protoDate;
}

const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

const todayYMD = (dayOffset: number = 0): string => {
  const date = new Date();
  date.setDate(date.getDate() + dayOffset);
  return formatDate(date);
}

const yearsAgoYMD = (yearsAgo: number): string => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - yearsAgo);
  return formatDate(date);
}

const dateToMaturity = (birthDate: string) => {
  const today = new Date()
  const birthDateObj = new Date(birthDate)
  let age = today.getFullYear() - birthDateObj.getFullYear()
  const monthDiff = today.getMonth() - birthDateObj.getMonth()
  const dayDiff = today.getDate() - birthDateObj.getUTCDate()

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) age--
  return age < 18 ? 'child' : 'adult'
}

export {
  dateToProtoDate,
  protoDateToInputString,
  todayYMD,
  yearsAgoYMD,
  dateToMaturity
}