<template>
    <div class="bg-white">
        <div class="container h-full flex items-center justify-between">
            <h1
                class="text-slate-800 text-2xl tracking-wide"
                data-testid="appTitle"
            >
                Online check-in
            </h1>
            <div class="flex space-x-4">
                <a
                    :href="config.public.supportUrl"
                    target="_blank"
                    class="!text-gray-600 !no-underline"
                    v-if="config.public.supportUrl"
                >
                    <PhosphorIconQuestion
                        size="24"
                        class="cursor-pointer md:hidden"
                    />
                    <span
                        class="hidden md:block text-gray-600 hover:text-gray-500"
                        >Need help?</span
                    >
                </a>
                <button
                    class="flex space-x-1 items-center text-gray-600 hover:text-alert-danger-600"
                    @click="abandonClaim"
                    title="Abandon checkin"
                    v-show="showAbandonClaim"
                >
                    <PhosphorIconXCircle size="24" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { MP_CHECKIN_ABANDONED } from "~/plugins/mixpanel.client";

const { $mixpanel } = useNuxtApp();
const config = useRuntimeConfig();
const route = useRoute();

const showAbandonClaim = computed(() => {
    return route.name !== "index";
});

const abandonClaim = () => {
    $mixpanel(MP_CHECKIN_ABANDONED);
    resetClientStorage(true);
};
</script>
