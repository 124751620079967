import mixpanel from "mixpanel-browser";
import { useRuntimeConfig } from "#app";
import { defineNuxtPlugin } from "#app";
import type { Guest } from "~/types/checkIn";
import {
  Reservation_GuestMetadata_Type,
  type Reservation_GuestMetadata,
} from "~/grpc/proto/shackle/backend/backend_pb";

// general
export const MP_CHECKIN_INIT = "Check-in Started";
export const MP_CHECKIN_ABANDONED = "Check-in Abandoned";

// /check-in OR /payment
export const MP_CHECKIN_COMPLETED = "Check-in Completed";

// root (claims)
export const MP_CLAIMED_BY_REF = "Claimed by Reference Code";
export const MP_CLAIMED_BY_INVITE = "Claimed by Invite Code";
export const MP_CLAIM_ERROR = "Claim Error";

// /check-in
export const MP_FORM_INIT = "Form Initialized";
export const MP_FORM_SUBMITTED = "Form Submitted";
export const MP_FORM_EDITED = "Form Edited";
export const MP_FORM_ERROR = "Form Error";

// /payment
export const MP_PAYMENT_INIT = "Payment Initialized";
export const MP_PAYMENT_COMPLETED = "Payment Completed";
export const MP_PAYMENT_REVERTED = "Payment Reverted";

// /confirmation
export const MP_CLICKED_PROMO = "Clicked Promo Link";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const mixpanelToken = config.public.mixpanelToken as string;

  const instance = mixpanel.init(
    mixpanelToken,
    {
      persistence: "localStorage",
      track_pageview: true,
      debug: false,
      ignore_dnt: true,
    },
    "shackle",
  );

  if (!mixpanelToken || import.meta.env.MODE === "test") {
    // provide a noop mixpanel method in case we fail to get a token, or we're testing
    nuxtApp.provide("mixpanel", () => {
      console.warn("Mixpanel was not initialized.");
    });
    return;
  }

  const track = (event: string, properties: Record<string, any>) => {
    const { property, reservation, guests } = useWebsiteStore();
    const { userId } = useUserStore();

    const processedGuests = guests?.map((guest: Guest) => {
      const { guestId, email, status, birthDate, countryOfResidence } = guest;
      return {
        guestId,
        email,
        status,
        birthDate,
        countryOfResidence,
      };
    });

    const defaultProperties = {
      propertyName: property?.name,
      propertyTimeZone: property?.timeZone,
      reservationName: reservation?.name,
      reservationStage: reservation?.stage,
      frontOfficeId: reservation?.frontOfficeId,
      guests: processedGuests,
      claimOwnerId: reservation?.guestMetadata?.find(
        (guest: Reservation_GuestMetadata) =>
          guest.type === Reservation_GuestMetadata_Type.OWNER,
      )?.id,
      userId: userId ?? null,
    };

    const combinedProperties = {
      ...defaultProperties,
      ...properties,
    };

    return instance.track(event, { ...combinedProperties, ...properties });
  };

  nuxtApp.provide("mixpanel", track);
});
