export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path === "/payment-session/failure" && to.query.reservationName) {
    return navigateTo({
      path: "/payment",
      query: {
        reservationName: to.query.reservationName,
        hasPaymentFailed: "true",
      },
    });
  }
  if (to.path === "/payment-session/success" && to.query.reservationName) {
    return navigateTo({
      path: "/confirmation",
      query: {
        reservationName: to.query.reservationName,
      },
    });
  }
});
