import { default as check_45inqjWKIVB6LkMeta } from "/app/pages/check-in.vue?macro=true";
import { default as confirmation0vlyIlsFyMMeta } from "/app/pages/confirmation.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as payment0EDeMiyhRyMeta } from "/app/pages/payment.vue?macro=true";
export default [
  {
    name: "check-in",
    path: "/check-in",
    meta: check_45inqjWKIVB6LkMeta || {},
    component: () => import("/app/pages/check-in.vue")
  },
  {
    name: "confirmation",
    path: "/confirmation",
    meta: confirmation0vlyIlsFyMMeta || {},
    component: () => import("/app/pages/confirmation.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/app/pages/payment.vue")
  }
]