// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file google/type/timeofday.proto (package google.type, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * Represents a time of day. The date and time zone are either not significant
 * or are specified elsewhere. An API may choose to allow leap seconds. Related
 * types are [google.type.Date][google.type.Date] and
 * `google.protobuf.Timestamp`.
 *
 * @generated from message google.type.TimeOfDay
 */
export class TimeOfDay extends Message<TimeOfDay> {
  /**
   * Hours of day in 24 hour format. Should be from 0 to 23. An API may choose
   * to allow the value "24:00:00" for scenarios like business closing time.
   *
   * @generated from field: int32 hours = 1;
   */
  hours = 0;

  /**
   * Minutes of hour of day. Must be from 0 to 59.
   *
   * @generated from field: int32 minutes = 2;
   */
  minutes = 0;

  /**
   * Seconds of minutes of the time. Must normally be from 0 to 59. An API may
   * allow the value 60 if it allows leap-seconds.
   *
   * @generated from field: int32 seconds = 3;
   */
  seconds = 0;

  /**
   * Fractions of seconds in nanoseconds. Must be from 0 to 999,999,999.
   *
   * @generated from field: int32 nanos = 4;
   */
  nanos = 0;

  constructor(data?: PartialMessage<TimeOfDay>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "google.type.TimeOfDay";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hours", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "minutes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "seconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "nanos", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimeOfDay {
    return new TimeOfDay().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimeOfDay {
    return new TimeOfDay().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimeOfDay {
    return new TimeOfDay().fromJsonString(jsonString, options);
  }

  static equals(a: TimeOfDay | PlainMessage<TimeOfDay> | undefined, b: TimeOfDay | PlainMessage<TimeOfDay> | undefined): boolean {
    return proto3.util.equals(TimeOfDay, a, b);
  }
}

