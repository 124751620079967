import { defineStore } from "pinia";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    userId: undefined as any,
    accessToken: "",
    refreshToken: "",
  }),
  actions: {
    async createUser() {
      // test if user is authenticated in cookies and set via those
      const session = useCookie("shackleAuth.session");

      // todo: remove this check for user id in few month when all users get new session
      if (
        session.value === undefined ||
        [
          "dpDdAlBkg39Q1fZE6ooom",
          "-re0aICIvH_z1a4vz9gfb",
          "zqZ0fnnV6g188nI2qoG7d",
          "oig75vgL8QXY5NHYzEqRP",
        ].includes(session?.value?.user?.id)
      ) {
        const event = useRequestEvent();
        const newSession = await fetchWithCookie(event!, "/api/auth");
        this.userId = newSession.user.id;
        this.accessToken = newSession.accessToken;
        this.refreshToken = newSession.refreshToken;
      } else {
        const { accessToken, refreshToken, user } = session.value;
        this.accessToken = accessToken;
        this.userId = user.id;
        this.refreshToken = refreshToken;
      }
    },
  },
});
