// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file shackle/common.proto (package shackle.common, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * payment gateways used by Shackle
 *
 * @generated from enum shackle.common.PaymentGateway
 */
export enum PaymentGateway {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: CHECKOUT = 1;
   */
  CHECKOUT = 1,

  /**
   * @generated from enum value: CONVERGE = 2;
   */
  CONVERGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentGateway)
proto3.util.setEnumType(PaymentGateway, "shackle.common.PaymentGateway", [
  { no: 0, name: "NONE" },
  { no: 1, name: "CHECKOUT" },
  { no: 2, name: "CONVERGE" },
]);

/**
 * @generated from enum shackle.common.PaymentMethod
 */
export enum PaymentMethod {
  /**
   * @generated from enum value: UNKNOWN_PAYMENT_METHOD = 0;
   */
  UNKNOWN_PAYMENT_METHOD = 0,

  /**
   * @generated from enum value: CARD = 1;
   */
  CARD = 1,

  /**
   * @generated from enum value: APPLE_PAY = 2;
   */
  APPLE_PAY = 2,

  /**
   * @generated from enum value: GOOGLE_PAY = 3;
   */
  GOOGLE_PAY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentMethod)
proto3.util.setEnumType(PaymentMethod, "shackle.common.PaymentMethod", [
  { no: 0, name: "UNKNOWN_PAYMENT_METHOD" },
  { no: 1, name: "CARD" },
  { no: 2, name: "APPLE_PAY" },
  { no: 3, name: "GOOGLE_PAY" },
]);

/**
 * @generated from enum shackle.common.PaymentScheme
 */
export enum PaymentScheme {
  /**
   * @generated from enum value: UNKNOWN_PAYMENT_SCHEME = 0;
   */
  UNKNOWN_PAYMENT_SCHEME = 0,

  /**
   * @generated from enum value: MASTERCARD = 1;
   */
  MASTERCARD = 1,

  /**
   * @generated from enum value: VISA = 2;
   */
  VISA = 2,

  /**
   * @generated from enum value: AMERICAN_EXPRESS = 3;
   */
  AMERICAN_EXPRESS = 3,

  /**
   * @generated from enum value: DINERS_CLUB = 4;
   */
  DINERS_CLUB = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentScheme)
proto3.util.setEnumType(PaymentScheme, "shackle.common.PaymentScheme", [
  { no: 0, name: "UNKNOWN_PAYMENT_SCHEME" },
  { no: 1, name: "MASTERCARD" },
  { no: 2, name: "VISA" },
  { no: 3, name: "AMERICAN_EXPRESS" },
  { no: 4, name: "DINERS_CLUB" },
]);

/**
 * @generated from enum shackle.common.PaymentType
 */
export enum PaymentType {
  /**
   * @generated from enum value: UNKNOWN_PAYMENT_TYPE = 0;
   */
  UNKNOWN_PAYMENT_TYPE = 0,

  /**
   * @generated from enum value: STAY = 1;
   */
  STAY = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentType)
proto3.util.setEnumType(PaymentType, "shackle.common.PaymentType", [
  { no: 0, name: "UNKNOWN_PAYMENT_TYPE" },
  { no: 1, name: "STAY" },
]);

/**
 * @generated from enum shackle.common.Region
 */
export enum Region {
  /**
   * @generated from enum value: eu = 0;
   */
  eu = 0,

  /**
   * @generated from enum value: uae = 1;
   */
  uae = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Region)
proto3.util.setEnumType(Region, "shackle.common.Region", [
  { no: 0, name: "eu" },
  { no: 1, name: "uae" },
]);

/**
 * @generated from enum shackle.common.BookingSource
 */
export enum BookingSource {
  /**
   * @generated from enum value: UNKNOWN_BOOKING_SOURCE = 0;
   */
  UNKNOWN_BOOKING_SOURCE = 0,

  /**
   * @generated from enum value: SHACKLE = 1;
   */
  SHACKLE = 1,

  /**
   * @generated from enum value: HOTEL_DIRECT = 2;
   */
  HOTEL_DIRECT = 2,

  /**
   * @generated from enum value: BOOKING_COM = 3;
   */
  BOOKING_COM = 3,

  /**
   * @generated from enum value: EXPEDIA_COM = 4;
   */
  EXPEDIA_COM = 4,

  /**
   * @generated from enum value: OTHER_SITE = 5;
   */
  OTHER_SITE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(BookingSource)
proto3.util.setEnumType(BookingSource, "shackle.common.BookingSource", [
  { no: 0, name: "UNKNOWN_BOOKING_SOURCE" },
  { no: 1, name: "SHACKLE" },
  { no: 2, name: "HOTEL_DIRECT" },
  { no: 3, name: "BOOKING_COM" },
  { no: 4, name: "EXPEDIA_COM" },
  { no: 5, name: "OTHER_SITE" },
]);

/**
 * @generated from enum shackle.common.DisplayIcon
 */
export enum DisplayIcon {
  /**
   * @generated from enum value: UNKNOWN_DISPLAY_ICON = 0;
   */
  UNKNOWN_DISPLAY_ICON = 0,

  /**
   * @generated from enum value: FILLED_CIRCLE_WARNING = 1;
   */
  FILLED_CIRCLE_WARNING = 1,

  /**
   * @generated from enum value: FILLED_CIRCLE_CHECK = 2;
   */
  FILLED_CIRCLE_CHECK = 2,

  /**
   * @generated from enum value: FILLED_CIRCLE_MINUS = 3;
   */
  FILLED_CIRCLE_MINUS = 3,

  /**
   * @generated from enum value: FILLED_CIRCLE_PLUS = 4;
   */
  FILLED_CIRCLE_PLUS = 4,

  /**
   * @generated from enum value: FILLED_CIRCLE_CROSS = 5;
   */
  FILLED_CIRCLE_CROSS = 5,

  /**
   * @generated from enum value: FILLED_CIRCLE_CHEVRON_LEFT = 6;
   */
  FILLED_CIRCLE_CHEVRON_LEFT = 6,

  /**
   * @generated from enum value: FILLED_CIRCLE_CHEVRON_RIGHT = 7;
   */
  FILLED_CIRCLE_CHEVRON_RIGHT = 7,

  /**
   * @generated from enum value: FILLED_CIRCLE_QUESTION = 8;
   */
  FILLED_CIRCLE_QUESTION = 8,

  /**
   * @generated from enum value: FILLED_CIRCLE_INFO = 9;
   */
  FILLED_CIRCLE_INFO = 9,

  /**
   * @generated from enum value: FILLED_CIRCLE_ARROW_UP = 10;
   */
  FILLED_CIRCLE_ARROW_UP = 10,

  /**
   * @generated from enum value: FILLED_CIRCLE_ARROW_LEFT = 11;
   */
  FILLED_CIRCLE_ARROW_LEFT = 11,

  /**
   * @generated from enum value: FILLED_CIRCLE_ARROW_RIGHT = 12;
   */
  FILLED_CIRCLE_ARROW_RIGHT = 12,

  /**
   * @generated from enum value: FILLED_SQUARE_CHECK = 13;
   */
  FILLED_SQUARE_CHECK = 13,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_WARNING = 14;
   */
  OUTLINE_CIRCLE_WARNING = 14,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_CHECK = 15;
   */
  OUTLINE_CIRCLE_CHECK = 15,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_MINUS = 16;
   */
  OUTLINE_CIRCLE_MINUS = 16,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_PLUS = 17;
   */
  OUTLINE_CIRCLE_PLUS = 17,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_CROSS = 18;
   */
  OUTLINE_CIRCLE_CROSS = 18,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_CHEVRON_LEFT = 19;
   */
  OUTLINE_CIRCLE_CHEVRON_LEFT = 19,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_CHEVRON_RIGHT = 20;
   */
  OUTLINE_CIRCLE_CHEVRON_RIGHT = 20,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_QUESTION = 21;
   */
  OUTLINE_CIRCLE_QUESTION = 21,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_INFO = 22;
   */
  OUTLINE_CIRCLE_INFO = 22,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_ARROW_UP = 23;
   */
  OUTLINE_CIRCLE_ARROW_UP = 23,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_ARROW_LEFT = 24;
   */
  OUTLINE_CIRCLE_ARROW_LEFT = 24,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_ARROW_RIGHT = 25;
   */
  OUTLINE_CIRCLE_ARROW_RIGHT = 25,

  /**
   * @generated from enum value: OUTLINE_SQUARE_CHECK = 26;
   */
  OUTLINE_SQUARE_CHECK = 26,

  /**
   * @generated from enum value: OUTLINE_LIST = 27;
   */
  OUTLINE_LIST = 27,

  /**
   * @generated from enum value: OUTLINE_PLUS = 28;
   */
  OUTLINE_PLUS = 28,

  /**
   * @generated from enum value: OUTLINE_MINUS = 29;
   */
  OUTLINE_MINUS = 29,

  /**
   * @generated from enum value: OUTLINE_CROSS = 30;
   */
  OUTLINE_CROSS = 30,

  /**
   * @generated from enum value: OUTLINE_MAGNIFYING_GLASS = 31;
   */
  OUTLINE_MAGNIFYING_GLASS = 31,

  /**
   * @generated from enum value: OUTLINE_GEAR = 32;
   */
  OUTLINE_GEAR = 32,

  /**
   * @generated from enum value: OUTLINE_CHEVRON_UP = 33;
   */
  OUTLINE_CHEVRON_UP = 33,

  /**
   * @generated from enum value: OUTLINE_CHEVRON_DOWN = 34;
   */
  OUTLINE_CHEVRON_DOWN = 34,

  /**
   * @generated from enum value: OUTLINE_CHEVRON_LEFT = 35;
   */
  OUTLINE_CHEVRON_LEFT = 35,

  /**
   * @generated from enum value: OUTLINE_CHEVRON_RIGHT = 36;
   */
  OUTLINE_CHEVRON_RIGHT = 36,

  /**
   * @generated from enum value: OUTLINE_USERS = 37;
   */
  OUTLINE_USERS = 37,

  /**
   * @generated from enum value: OUTLINE_REFRESH = 38;
   */
  OUTLINE_REFRESH = 38,

  /**
   * @generated from enum value: OUTLINE_CALENDAR = 39;
   */
  OUTLINE_CALENDAR = 39,

  /**
   * @generated from enum value: OUTLINE_STAR = 40;
   */
  OUTLINE_STAR = 40,

  /**
   * @generated from enum value: OUTLINE_CHATS = 41;
   */
  OUTLINE_CHATS = 41,

  /**
   * @generated from enum value: OUTLINE_SQUARES = 42;
   */
  OUTLINE_SQUARES = 42,

  /**
   * @generated from enum value: OUTLINE_ARROW_LEFT = 43;
   */
  OUTLINE_ARROW_LEFT = 43,

  /**
   * @generated from enum value: OUTLINE_ARROW_RIGHT = 44;
   */
  OUTLINE_ARROW_RIGHT = 44,

  /**
   * @generated from enum value: OUTLINE_ARROW_UP = 45;
   */
  OUTLINE_ARROW_UP = 45,

  /**
   * @generated from enum value: OUTLINE_ARROW_DOWN = 46;
   */
  OUTLINE_ARROW_DOWN = 46,

  /**
   * @generated from enum value: OUTLINE_CLOCK = 47;
   */
  OUTLINE_CLOCK = 47,

  /**
   * @generated from enum value: OUTLINE_CAMERA = 48;
   */
  OUTLINE_CAMERA = 48,

  /**
   * @generated from enum value: OUTLINE_CREDIT_CARD = 49;
   */
  OUTLINE_CREDIT_CARD = 49,

  /**
   * @generated from enum value: OUTLINE_LINK = 50;
   */
  OUTLINE_LINK = 50,

  /**
   * @generated from enum value: OUTLINE_CHECK = 51;
   */
  OUTLINE_CHECK = 51,

  /**
   * @generated from enum value: OUTLINE_SHOPPING_CART = 52;
   */
  OUTLINE_SHOPPING_CART = 52,

  /**
   * @generated from enum value: OUTLINE_TRASH_CAN = 53;
   */
  OUTLINE_TRASH_CAN = 53,

  /**
   * @generated from enum value: OUTLINE_LEAF = 54;
   */
  OUTLINE_LEAF = 54,

  /**
   * @generated from enum value: OUTLINE_GLUTEN_FREE = 55;
   */
  OUTLINE_GLUTEN_FREE = 55,

  /**
   * @generated from enum value: OUTLINE_COOKING_POT = 56;
   */
  OUTLINE_COOKING_POT = 56,

  /**
   * @generated from enum value: OUTLINE_KNIFE = 57;
   */
  OUTLINE_KNIFE = 57,

  /**
   * @generated from enum value: OUTLINE_FORK_AND_KNIFE = 58;
   */
  OUTLINE_FORK_AND_KNIFE = 58,

  /**
   * @generated from enum value: OUTLINE_DOOR = 59;
   */
  OUTLINE_DOOR = 59,

  /**
   * @generated from enum value: OUTLINE_SHOPPING_BAG = 60;
   */
  OUTLINE_SHOPPING_BAG = 60,

  /**
   * @generated from enum value: OUTLINE_RECEIPT = 61;
   */
  OUTLINE_RECEIPT = 61,

  /**
   * @generated from enum value: OUTLINE_WALLET = 63;
   */
  OUTLINE_WALLET = 63,

  /**
   * @generated from enum value: OUTLINE_PENCIL = 64;
   */
  OUTLINE_PENCIL = 64,

  /**
   * @generated from enum value: OUTLINE_ARROWS_OUT = 65;
   */
  OUTLINE_ARROWS_OUT = 65,

  /**
   * @generated from enum value: OUTLINE_ARROWS_IN = 66;
   */
  OUTLINE_ARROWS_IN = 66,

  /**
   * @generated from enum value: OUTLINE_COIN = 67;
   */
  OUTLINE_COIN = 67,

  /**
   * @generated from enum value: OUTLINE_PRINTER = 68;
   */
  OUTLINE_PRINTER = 68,

  /**
   * @generated from enum value: OUTLINE_ID_CARD = 69;
   */
  OUTLINE_ID_CARD = 69,

  /**
   * @generated from enum value: OUTLINE_TELEPHONE = 70;
   */
  OUTLINE_TELEPHONE = 70,

  /**
   * @generated from enum value: OUTLINE_ENVELOPE = 71;
   */
  OUTLINE_ENVELOPE = 71,

  /**
   * @generated from enum value: OUTLINE_GLOBE = 72;
   */
  OUTLINE_GLOBE = 72,

  /**
   * @generated from enum value: OUTLINE_MAP_PIN = 73;
   */
  OUTLINE_MAP_PIN = 73,

  /**
   * @generated from enum value: OUTLINE_KEY = 74;
   */
  OUTLINE_KEY = 74,

  /**
   * @generated from enum value: OUTLINE_CALL_BELL = 75;
   */
  OUTLINE_CALL_BELL = 75,

  /**
   * @generated from enum value: OUTLINE_ARROW_CLOCKWISE = 76;
   */
  OUTLINE_ARROW_CLOCKWISE = 76,

  /**
   * @generated from enum value: OUTLINE_SPINNER = 77;
   */
  OUTLINE_SPINNER = 77,

  /**
   * @generated from enum value: OUTLINE_MICROPHONE = 78;
   */
  OUTLINE_MICROPHONE = 78,

  /**
   * @generated from enum value: OUTLINE_SLIDERS = 79;
   */
  OUTLINE_SLIDERS = 79,

  /**
   * @generated from enum value: OUTLINE_COMPASS = 80;
   */
  OUTLINE_COMPASS = 80,

  /**
   * @generated from enum value: OUTLINE_PAW = 81;
   */
  OUTLINE_PAW = 81,

  /**
   * @generated from enum value: OUTLINE_COAT_HANGER = 82;
   */
  OUTLINE_COAT_HANGER = 82,

  /**
   * @generated from enum value: OUTLINE_BED = 83;
   */
  OUTLINE_BED = 83,

  /**
   * @generated from enum value: OUTLINE_TAXI = 84;
   */
  OUTLINE_TAXI = 84,

  /**
   * @generated from enum value: OUTLINE_SHOWER = 85;
   */
  OUTLINE_SHOWER = 85,

  /**
   * @generated from enum value: OUTLINE_WIFI = 86;
   */
  OUTLINE_WIFI = 86,

  /**
   * @generated from enum value: OUTLINE_SUITCASE = 87;
   */
  OUTLINE_SUITCASE = 87,

  /**
   * @generated from enum value: OUTLINE_BARBELL = 88;
   */
  OUTLINE_BARBELL = 88,

  /**
   * @generated from enum value: OUTLINE_BATH = 89;
   */
  OUTLINE_BATH = 89,

  /**
   * @generated from enum value: OUTLINE_CAR = 90;
   */
  OUTLINE_CAR = 90,

  /**
   * @generated from enum value: OUTLINE_HAMBURGER = 91;
   */
  OUTLINE_HAMBURGER = 91,

  /**
   * @generated from enum value: OUTLINE_WINE = 92;
   */
  OUTLINE_WINE = 92,

  /**
   * @generated from enum value: OUTLINE_LOCK = 93;
   */
  OUTLINE_LOCK = 93,

  /**
   * @generated from enum value: OUTLINE_UNLOCK = 94;
   */
  OUTLINE_UNLOCK = 94,

  /**
   * @generated from enum value: OUTLINE_SHIELD = 95;
   */
  OUTLINE_SHIELD = 95,

  /**
   * @generated from enum value: OUTLINE_LIGHT_BULB = 96;
   */
  OUTLINE_LIGHT_BULB = 96,

  /**
   * @generated from enum value: OUTLINE_DOWNLOAD = 97;
   */
  OUTLINE_DOWNLOAD = 97,

  /**
   * @generated from enum value: OUTLINE_UPLOAD = 98;
   */
  OUTLINE_UPLOAD = 98,

  /**
   * @generated from enum value: OUTLINE_GIFT = 99;
   */
  OUTLINE_GIFT = 99,

  /**
   * @generated from enum value: OUTLINE_SPEAKER = 100;
   */
  OUTLINE_SPEAKER = 100,

  /**
   * @generated from enum value: OUTLINE_THREE_DOTS = 101;
   */
  OUTLINE_THREE_DOTS = 101,

  /**
   * @generated from enum value: OUTLINE_CHAT = 102;
   */
  OUTLINE_CHAT = 102,

  /**
   * @generated from enum value: OUTLINE_IMAGE = 103;
   */
  OUTLINE_IMAGE = 103,

  /**
   * @generated from enum value: OUTLINE_WAVE = 104;
   */
  OUTLINE_WAVE = 104,

  /**
   * @generated from enum value: OUTLINE_SORT = 105;
   */
  OUTLINE_SORT = 105,

  /**
   * @generated from enum value: OUTLINE_FUNNEL = 106;
   */
  OUTLINE_FUNNEL = 106,

  /**
   * @generated from enum value: OUTLINE_HOUSE = 107;
   */
  OUTLINE_HOUSE = 107,

  /**
   * @generated from enum value: OUTLINE_HOURGLASS = 108;
   */
  OUTLINE_HOURGLASS = 108,

  /**
   * @generated from enum value: OUTLINE_SMILEY = 109;
   */
  OUTLINE_SMILEY = 109,

  /**
   * @generated from enum value: OUTLINE_TWO_ARROWS_CLOCKWISE = 110;
   */
  OUTLINE_TWO_ARROWS_CLOCKWISE = 110,

  /**
   * @generated from enum value: OUTLINE_MOON = 111;
   */
  OUTLINE_MOON = 111,

  /**
   * @generated from enum value: OUTLINE_COPY = 112;
   */
  OUTLINE_COPY = 112,

  /**
   * @generated from enum value: OUTLINE_ADDRESS_BOOK = 113;
   */
  OUTLINE_ADDRESS_BOOK = 113,

  /**
   * @generated from enum value: OUTLINE_EXPORT = 114;
   */
  OUTLINE_EXPORT = 114,

  /**
   * @generated from enum value: OUTLINE_BELL = 115;
   */
  OUTLINE_BELL = 115,

  /**
   * @generated from enum value: OUTLINE_ID_SCAN = 116;
   */
  OUTLINE_ID_SCAN = 116,

  /**
   * @generated from enum value: OUTLINE_ID_CHECK = 117;
   */
  OUTLINE_ID_CHECK = 117,

  /**
   * @generated from enum value: OUTLINE_MOBILE_KEY = 118;
   */
  OUTLINE_MOBILE_KEY = 118,

  /**
   * @generated from enum value: OUTLINE_SQUARE = 119;
   */
  OUTLINE_SQUARE = 119,

  /**
   * @generated from enum value: OUTLINE_MISSING_IMAGE = 120;
   */
  OUTLINE_MISSING_IMAGE = 120,

  /**
   * @generated from enum value: OUTLINE_CAMERA_SLASH = 121;
   */
  OUTLINE_CAMERA_SLASH = 121,

  /**
   * @generated from enum value: OUTLINE_FORM_LIST = 122;
   */
  OUTLINE_FORM_LIST = 122,

  /**
   * @generated from enum value: OUTLINE_CHAT_QUESTION_MARK = 123;
   */
  OUTLINE_CHAT_QUESTION_MARK = 123,

  /**
   * @generated from enum value: OUTLINE_THREE_DOTS_HORIZONTAL = 125;
   */
  OUTLINE_THREE_DOTS_HORIZONTAL = 125,

  /**
   * @generated from enum value: OUTLINE_CONFETTI = 126;
   */
  OUTLINE_CONFETTI = 126,

  /**
   * @generated from enum value: OUTLINE_HOURGLASS_HIGH = 127;
   */
  OUTLINE_HOURGLASS_HIGH = 127,

  /**
   * @generated from enum value: OUTLINE_CLOCK_COUNTDOWN = 128;
   */
  OUTLINE_CLOCK_COUNTDOWN = 128,

  /**
   * @generated from enum value: OUTLINE_TIMER = 129;
   */
  OUTLINE_TIMER = 129,

  /**
   * @generated from enum value: OUTLINE_CIRCLE_NOTCH = 130;
   */
  OUTLINE_CIRCLE_NOTCH = 130,

  /**
   * @generated from enum value: OUTLINE_LOADER = 131;
   */
  OUTLINE_LOADER = 131,
}
// Retrieve enum metadata with: proto3.getEnumType(DisplayIcon)
proto3.util.setEnumType(DisplayIcon, "shackle.common.DisplayIcon", [
  { no: 0, name: "UNKNOWN_DISPLAY_ICON" },
  { no: 1, name: "FILLED_CIRCLE_WARNING" },
  { no: 2, name: "FILLED_CIRCLE_CHECK" },
  { no: 3, name: "FILLED_CIRCLE_MINUS" },
  { no: 4, name: "FILLED_CIRCLE_PLUS" },
  { no: 5, name: "FILLED_CIRCLE_CROSS" },
  { no: 6, name: "FILLED_CIRCLE_CHEVRON_LEFT" },
  { no: 7, name: "FILLED_CIRCLE_CHEVRON_RIGHT" },
  { no: 8, name: "FILLED_CIRCLE_QUESTION" },
  { no: 9, name: "FILLED_CIRCLE_INFO" },
  { no: 10, name: "FILLED_CIRCLE_ARROW_UP" },
  { no: 11, name: "FILLED_CIRCLE_ARROW_LEFT" },
  { no: 12, name: "FILLED_CIRCLE_ARROW_RIGHT" },
  { no: 13, name: "FILLED_SQUARE_CHECK" },
  { no: 14, name: "OUTLINE_CIRCLE_WARNING" },
  { no: 15, name: "OUTLINE_CIRCLE_CHECK" },
  { no: 16, name: "OUTLINE_CIRCLE_MINUS" },
  { no: 17, name: "OUTLINE_CIRCLE_PLUS" },
  { no: 18, name: "OUTLINE_CIRCLE_CROSS" },
  { no: 19, name: "OUTLINE_CIRCLE_CHEVRON_LEFT" },
  { no: 20, name: "OUTLINE_CIRCLE_CHEVRON_RIGHT" },
  { no: 21, name: "OUTLINE_CIRCLE_QUESTION" },
  { no: 22, name: "OUTLINE_CIRCLE_INFO" },
  { no: 23, name: "OUTLINE_CIRCLE_ARROW_UP" },
  { no: 24, name: "OUTLINE_CIRCLE_ARROW_LEFT" },
  { no: 25, name: "OUTLINE_CIRCLE_ARROW_RIGHT" },
  { no: 26, name: "OUTLINE_SQUARE_CHECK" },
  { no: 27, name: "OUTLINE_LIST" },
  { no: 28, name: "OUTLINE_PLUS" },
  { no: 29, name: "OUTLINE_MINUS" },
  { no: 30, name: "OUTLINE_CROSS" },
  { no: 31, name: "OUTLINE_MAGNIFYING_GLASS" },
  { no: 32, name: "OUTLINE_GEAR" },
  { no: 33, name: "OUTLINE_CHEVRON_UP" },
  { no: 34, name: "OUTLINE_CHEVRON_DOWN" },
  { no: 35, name: "OUTLINE_CHEVRON_LEFT" },
  { no: 36, name: "OUTLINE_CHEVRON_RIGHT" },
  { no: 37, name: "OUTLINE_USERS" },
  { no: 38, name: "OUTLINE_REFRESH" },
  { no: 39, name: "OUTLINE_CALENDAR" },
  { no: 40, name: "OUTLINE_STAR" },
  { no: 41, name: "OUTLINE_CHATS" },
  { no: 42, name: "OUTLINE_SQUARES" },
  { no: 43, name: "OUTLINE_ARROW_LEFT" },
  { no: 44, name: "OUTLINE_ARROW_RIGHT" },
  { no: 45, name: "OUTLINE_ARROW_UP" },
  { no: 46, name: "OUTLINE_ARROW_DOWN" },
  { no: 47, name: "OUTLINE_CLOCK" },
  { no: 48, name: "OUTLINE_CAMERA" },
  { no: 49, name: "OUTLINE_CREDIT_CARD" },
  { no: 50, name: "OUTLINE_LINK" },
  { no: 51, name: "OUTLINE_CHECK" },
  { no: 52, name: "OUTLINE_SHOPPING_CART" },
  { no: 53, name: "OUTLINE_TRASH_CAN" },
  { no: 54, name: "OUTLINE_LEAF" },
  { no: 55, name: "OUTLINE_GLUTEN_FREE" },
  { no: 56, name: "OUTLINE_COOKING_POT" },
  { no: 57, name: "OUTLINE_KNIFE" },
  { no: 58, name: "OUTLINE_FORK_AND_KNIFE" },
  { no: 59, name: "OUTLINE_DOOR" },
  { no: 60, name: "OUTLINE_SHOPPING_BAG" },
  { no: 61, name: "OUTLINE_RECEIPT" },
  { no: 63, name: "OUTLINE_WALLET" },
  { no: 64, name: "OUTLINE_PENCIL" },
  { no: 65, name: "OUTLINE_ARROWS_OUT" },
  { no: 66, name: "OUTLINE_ARROWS_IN" },
  { no: 67, name: "OUTLINE_COIN" },
  { no: 68, name: "OUTLINE_PRINTER" },
  { no: 69, name: "OUTLINE_ID_CARD" },
  { no: 70, name: "OUTLINE_TELEPHONE" },
  { no: 71, name: "OUTLINE_ENVELOPE" },
  { no: 72, name: "OUTLINE_GLOBE" },
  { no: 73, name: "OUTLINE_MAP_PIN" },
  { no: 74, name: "OUTLINE_KEY" },
  { no: 75, name: "OUTLINE_CALL_BELL" },
  { no: 76, name: "OUTLINE_ARROW_CLOCKWISE" },
  { no: 77, name: "OUTLINE_SPINNER" },
  { no: 78, name: "OUTLINE_MICROPHONE" },
  { no: 79, name: "OUTLINE_SLIDERS" },
  { no: 80, name: "OUTLINE_COMPASS" },
  { no: 81, name: "OUTLINE_PAW" },
  { no: 82, name: "OUTLINE_COAT_HANGER" },
  { no: 83, name: "OUTLINE_BED" },
  { no: 84, name: "OUTLINE_TAXI" },
  { no: 85, name: "OUTLINE_SHOWER" },
  { no: 86, name: "OUTLINE_WIFI" },
  { no: 87, name: "OUTLINE_SUITCASE" },
  { no: 88, name: "OUTLINE_BARBELL" },
  { no: 89, name: "OUTLINE_BATH" },
  { no: 90, name: "OUTLINE_CAR" },
  { no: 91, name: "OUTLINE_HAMBURGER" },
  { no: 92, name: "OUTLINE_WINE" },
  { no: 93, name: "OUTLINE_LOCK" },
  { no: 94, name: "OUTLINE_UNLOCK" },
  { no: 95, name: "OUTLINE_SHIELD" },
  { no: 96, name: "OUTLINE_LIGHT_BULB" },
  { no: 97, name: "OUTLINE_DOWNLOAD" },
  { no: 98, name: "OUTLINE_UPLOAD" },
  { no: 99, name: "OUTLINE_GIFT" },
  { no: 100, name: "OUTLINE_SPEAKER" },
  { no: 101, name: "OUTLINE_THREE_DOTS" },
  { no: 102, name: "OUTLINE_CHAT" },
  { no: 103, name: "OUTLINE_IMAGE" },
  { no: 104, name: "OUTLINE_WAVE" },
  { no: 105, name: "OUTLINE_SORT" },
  { no: 106, name: "OUTLINE_FUNNEL" },
  { no: 107, name: "OUTLINE_HOUSE" },
  { no: 108, name: "OUTLINE_HOURGLASS" },
  { no: 109, name: "OUTLINE_SMILEY" },
  { no: 110, name: "OUTLINE_TWO_ARROWS_CLOCKWISE" },
  { no: 111, name: "OUTLINE_MOON" },
  { no: 112, name: "OUTLINE_COPY" },
  { no: 113, name: "OUTLINE_ADDRESS_BOOK" },
  { no: 114, name: "OUTLINE_EXPORT" },
  { no: 115, name: "OUTLINE_BELL" },
  { no: 116, name: "OUTLINE_ID_SCAN" },
  { no: 117, name: "OUTLINE_ID_CHECK" },
  { no: 118, name: "OUTLINE_MOBILE_KEY" },
  { no: 119, name: "OUTLINE_SQUARE" },
  { no: 120, name: "OUTLINE_MISSING_IMAGE" },
  { no: 121, name: "OUTLINE_CAMERA_SLASH" },
  { no: 122, name: "OUTLINE_FORM_LIST" },
  { no: 123, name: "OUTLINE_CHAT_QUESTION_MARK" },
  { no: 125, name: "OUTLINE_THREE_DOTS_HORIZONTAL" },
  { no: 126, name: "OUTLINE_CONFETTI" },
  { no: 127, name: "OUTLINE_HOURGLASS_HIGH" },
  { no: 128, name: "OUTLINE_CLOCK_COUNTDOWN" },
  { no: 129, name: "OUTLINE_TIMER" },
  { no: 130, name: "OUTLINE_CIRCLE_NOTCH" },
  { no: 131, name: "OUTLINE_LOADER" },
]);

/**
 * @generated from enum shackle.common.DisplayColor
 */
export enum DisplayColor {
  /**
   * @generated from enum value: UNKNOWN_DISPLAY_COLOR = 0;
   */
  UNKNOWN_DISPLAY_COLOR = 0,

  /**
   * @generated from enum value: TURQUOISE = 1;
   */
  TURQUOISE = 1,

  /**
   * @generated from enum value: RED = 2;
   */
  RED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DisplayColor)
proto3.util.setEnumType(DisplayColor, "shackle.common.DisplayColor", [
  { no: 0, name: "UNKNOWN_DISPLAY_COLOR" },
  { no: 1, name: "TURQUOISE" },
  { no: 2, name: "RED" },
]);

/**
 * DO NOT use for new fields, use googleapi, only in use for birthday in RegCard v1
 *
 * @generated from message shackle.common.LocalDate
 */
export class LocalDate extends Message<LocalDate> {
  /**
   * @generated from field: int32 year = 1;
   */
  year = 0;

  /**
   * @generated from field: int32 month = 2;
   */
  month = 0;

  /**
   * @generated from field: int32 day = 3;
   */
  day = 0;

  constructor(data?: PartialMessage<LocalDate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.LocalDate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocalDate {
    return new LocalDate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocalDate {
    return new LocalDate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocalDate {
    return new LocalDate().fromJsonString(jsonString, options);
  }

  static equals(a: LocalDate | PlainMessage<LocalDate> | undefined, b: LocalDate | PlainMessage<LocalDate> | undefined): boolean {
    return proto3.util.equals(LocalDate, a, b);
  }
}

/**
 * @generated from message shackle.common.Failure
 */
export class Failure extends Message<Failure> {
  /**
   * @generated from field: shackle.common.Failure.Code code = 1;
   */
  code = Failure_Code.UNKNOWN;

  /**
   * Message is for internal use only.
   * Use the code to provide a localized message for the external user.
   *
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<Failure>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Failure";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "enum", T: proto3.getEnumType(Failure_Code) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Failure {
    return new Failure().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Failure {
    return new Failure().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Failure {
    return new Failure().fromJsonString(jsonString, options);
  }

  static equals(a: Failure | PlainMessage<Failure> | undefined, b: Failure | PlainMessage<Failure> | undefined): boolean {
    return proto3.util.equals(Failure, a, b);
  }
}

/**
 * @generated from enum shackle.common.Failure.Code
 */
export enum Failure_Code {
  /**
   * When a new code is added which a client doesn't know, protobuf will default to this value.
   * Use this to check whether a client must be updated.
   *
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Reservation is not supported by the property for use with Shackle (e.g. multiple rooms at launch or unpaid)
   *
   * @generated from enum value: UNSUPPORTED_RESERVATION = 1;
   */
  UNSUPPORTED_RESERVATION = 1,

  /**
   * ID verification is required to proceed
   *
   * @generated from enum value: ID_VERIFICATION_REQUIRED = 2;
   */
  ID_VERIFICATION_REQUIRED = 2,

  /**
   * Could not check in at PMS
   *
   * @generated from enum value: COULD_NOT_CHECK_IN = 3;
   */
  COULD_NOT_CHECK_IN = 3,

  /**
   * Reservation has non zero balance
   *
   * @generated from enum value: NON_ZERO_BALANCE = 4;
   */
  NON_ZERO_BALANCE = 4,

  /**
   * Reservation is not marked as due out
   *
   * @generated from enum value: NOT_DUE_OUT = 5;
   */
  NOT_DUE_OUT = 5,

  /**
   * Could not check out at PMS
   *
   * @generated from enum value: COULD_NOT_CHECK_OUT = 6;
   */
  COULD_NOT_CHECK_OUT = 6,

  /**
   * Could not provison keys with key service
   *
   * @generated from enum value: COULD_NOT_PROVISION_KEYS = 7;
   */
  COULD_NOT_PROVISION_KEYS = 7,

  /**
   * Remote check in not allowed by hotel, but other usage of the app is (contrary to UNSUPPORTED_RESERVATION)
   *
   * @generated from enum value: REMOTE_CHECK_IN_NOT_ALLOWED = 8;
   */
  REMOTE_CHECK_IN_NOT_ALLOWED = 8,

  /**
   * Remote checkout not allowed, reservation has multiple open windows and need to closed manually at reception.
   *
   * @generated from enum value: REMOTE_CHECKOUT_NOT_ALLOWED_MULTIPLE_OPEN_WINDOWS = 9;
   */
  REMOTE_CHECKOUT_NOT_ALLOWED_MULTIPLE_OPEN_WINDOWS = 9,

  /**
   * Remote checkout not allowed, reservation has no authorized payment.
   *
   * @generated from enum value: REMOTE_CHECKOUT_NOT_ALLOWED_NO_AUTHORIZED_PAYMENT = 10;
   */
  REMOTE_CHECKOUT_NOT_ALLOWED_NO_AUTHORIZED_PAYMENT = 10,

  /**
   * Remote check in not allowed, reservation has multiple open windows.
   *
   * @generated from enum value: REMOTE_CHECK_IN_NOT_ALLOWED_MULTIPLE_OPEN_WINDOWS = 11;
   */
  REMOTE_CHECK_IN_NOT_ALLOWED_MULTIPLE_OPEN_WINDOWS = 11,

  /**
   * Remote check in not allowed, reservation duration is out of authorization period bounds.
   *
   * @generated from enum value: REMOTE_CHECK_IN_NOT_ALLOWED_DURATION_OUT_OF_BOUNDS = 12;
   */
  REMOTE_CHECK_IN_NOT_ALLOWED_DURATION_OUT_OF_BOUNDS = 12,

  /**
   * Remote check in not allowed, the property does not support payment via shackle.
   *
   * @generated from enum value: REMOTE_CHECK_IN_NOT_ALLOWED_PAYMENT_NOT_SUPPORTED = 13;
   */
  REMOTE_CHECK_IN_NOT_ALLOWED_PAYMENT_NOT_SUPPORTED = 13,

  /**
   * The reservation payment was captured before the reservation has been checked out.
   *
   * @generated from enum value: PAYMENT_CAPTURE_ATTEMPTED_BEFORE_CHECK_OUT = 14;
   */
  PAYMENT_CAPTURE_ATTEMPTED_BEFORE_CHECK_OUT = 14,

  /**
   * The reservation can not be checked out by shackle, as it has a negative invoice amount.
   *
   * @generated from enum value: REMOTE_CHECKOUT_NOT_ALLOWED_NEGATIVE_INVOICE_AMOUNT = 15;
   */
  REMOTE_CHECKOUT_NOT_ALLOWED_NEGATIVE_INVOICE_AMOUNT = 15,

  /**
   * The reservation payment was not captured at checkout.
   *
   * @generated from enum value: PAYMENT_NOT_CAPTURED_AT_CHECKOUT = 16;
   */
  PAYMENT_NOT_CAPTURED_AT_CHECKOUT = 16,
}
// Retrieve enum metadata with: proto3.getEnumType(Failure_Code)
proto3.util.setEnumType(Failure_Code, "shackle.common.Failure.Code", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "UNSUPPORTED_RESERVATION" },
  { no: 2, name: "ID_VERIFICATION_REQUIRED" },
  { no: 3, name: "COULD_NOT_CHECK_IN" },
  { no: 4, name: "NON_ZERO_BALANCE" },
  { no: 5, name: "NOT_DUE_OUT" },
  { no: 6, name: "COULD_NOT_CHECK_OUT" },
  { no: 7, name: "COULD_NOT_PROVISION_KEYS" },
  { no: 8, name: "REMOTE_CHECK_IN_NOT_ALLOWED" },
  { no: 9, name: "REMOTE_CHECKOUT_NOT_ALLOWED_MULTIPLE_OPEN_WINDOWS" },
  { no: 10, name: "REMOTE_CHECKOUT_NOT_ALLOWED_NO_AUTHORIZED_PAYMENT" },
  { no: 11, name: "REMOTE_CHECK_IN_NOT_ALLOWED_MULTIPLE_OPEN_WINDOWS" },
  { no: 12, name: "REMOTE_CHECK_IN_NOT_ALLOWED_DURATION_OUT_OF_BOUNDS" },
  { no: 13, name: "REMOTE_CHECK_IN_NOT_ALLOWED_PAYMENT_NOT_SUPPORTED" },
  { no: 14, name: "PAYMENT_CAPTURE_ATTEMPTED_BEFORE_CHECK_OUT" },
  { no: 15, name: "REMOTE_CHECKOUT_NOT_ALLOWED_NEGATIVE_INVOICE_AMOUNT" },
  { no: 16, name: "PAYMENT_NOT_CAPTURED_AT_CHECKOUT" },
]);

/**
 * @generated from message shackle.common.Money
 */
export class Money extends Message<Money> {
  /**
   * ISO 4217 currency code
   *
   * @generated from field: string currency = 1;
   */
  currency = "";

  /**
   * A string representation with a . as decimal separator and significant zeros without exponent field.
   * Use https://docs.oracle.com/en/java/javase/17/docs/api/java.base/java/math/BigDecimal.html#toPlainString()
   * Also use BigDecimal / Decimal object to do calculations.
   *
   * @generated from field: string amount = 2;
   */
  amount = "";

  constructor(data?: PartialMessage<Money>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Money";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Money {
    return new Money().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJsonString(jsonString, options);
  }

  static equals(a: Money | PlainMessage<Money> | undefined, b: Money | PlainMessage<Money> | undefined): boolean {
    return proto3.util.equals(Money, a, b);
  }
}

/**
 * @generated from message shackle.common.Alerts
 */
export class Alerts extends Message<Alerts> {
  /**
   * @generated from field: repeated shackle.common.Failure failure = 1;
   */
  failure: Failure[] = [];

  /**
   * @generated from field: repeated shackle.common.Alerts.Warning warning = 2;
   */
  warning: Alerts_Warning[] = [];

  constructor(data?: PartialMessage<Alerts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Alerts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "failure", kind: "message", T: Failure, repeated: true },
    { no: 2, name: "warning", kind: "message", T: Alerts_Warning, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Alerts {
    return new Alerts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Alerts {
    return new Alerts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Alerts {
    return new Alerts().fromJsonString(jsonString, options);
  }

  static equals(a: Alerts | PlainMessage<Alerts> | undefined, b: Alerts | PlainMessage<Alerts> | undefined): boolean {
    return proto3.util.equals(Alerts, a, b);
  }
}

/**
 * @generated from message shackle.common.Alerts.Warning
 */
export class Alerts_Warning extends Message<Alerts_Warning> {
  /**
   * @generated from field: shackle.common.Alerts.Warning.Code code = 1;
   */
  code = Alerts_Warning_Code.UNKNOWN;

  /**
   * Message is for internal use only.
   * Use the code to provide a localized message for the external user.
   *
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<Alerts_Warning>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.Alerts.Warning";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "enum", T: proto3.getEnumType(Alerts_Warning_Code) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Alerts_Warning {
    return new Alerts_Warning().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Alerts_Warning {
    return new Alerts_Warning().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Alerts_Warning {
    return new Alerts_Warning().fromJsonString(jsonString, options);
  }

  static equals(a: Alerts_Warning | PlainMessage<Alerts_Warning> | undefined, b: Alerts_Warning | PlainMessage<Alerts_Warning> | undefined): boolean {
    return proto3.util.equals(Alerts_Warning, a, b);
  }
}

/**
 * @generated from enum shackle.common.Alerts.Warning.Code
 */
export enum Alerts_Warning_Code {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * When a user checked out through the app, or is due out and other non-primary guest windows require closure
   *
   * @generated from enum value: PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE = 1;
   */
  PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Alerts_Warning_Code)
proto3.util.setEnumType(Alerts_Warning_Code, "shackle.common.Alerts.Warning.Code", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE" },
]);

/**
 * Since string is already translated on backend, backend can pass pure string
 * that needs to be shown as is
 *
 * @generated from message shackle.common.BackendTranslatedString
 */
export class BackendTranslatedString extends Message<BackendTranslatedString> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<BackendTranslatedString>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.BackendTranslatedString";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BackendTranslatedString {
    return new BackendTranslatedString().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BackendTranslatedString {
    return new BackendTranslatedString().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BackendTranslatedString {
    return new BackendTranslatedString().fromJsonString(jsonString, options);
  }

  static equals(a: BackendTranslatedString | PlainMessage<BackendTranslatedString> | undefined, b: BackendTranslatedString | PlainMessage<BackendTranslatedString> | undefined): boolean {
    return proto3.util.equals(BackendTranslatedString, a, b);
  }
}

/**
 * Client translated string requires 2 strings, one key and arguments so it can adjust
 * translation to different context like if person is single or with family
 * translation will have different text plurals and etc.
 *
 * @generated from message shackle.common.ClientTranslatedString
 */
export class ClientTranslatedString extends Message<ClientTranslatedString> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: repeated string args = 2;
   */
  args: string[] = [];

  constructor(data?: PartialMessage<ClientTranslatedString>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.ClientTranslatedString";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "args", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClientTranslatedString {
    return new ClientTranslatedString().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClientTranslatedString {
    return new ClientTranslatedString().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClientTranslatedString {
    return new ClientTranslatedString().fromJsonString(jsonString, options);
  }

  static equals(a: ClientTranslatedString | PlainMessage<ClientTranslatedString> | undefined, b: ClientTranslatedString | PlainMessage<ClientTranslatedString> | undefined): boolean {
    return proto3.util.equals(ClientTranslatedString, a, b);
  }
}

/**
 * Dynamic string is a string that could be translated either from Backend or Client
 *
 * @generated from message shackle.common.DynamicString
 */
export class DynamicString extends Message<DynamicString> {
  /**
   * @generated from field: shackle.common.BackendTranslatedString backend = 1;
   */
  backend?: BackendTranslatedString;

  /**
   * @generated from field: shackle.common.ClientTranslatedString client = 2;
   */
  client?: ClientTranslatedString;

  constructor(data?: PartialMessage<DynamicString>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.DynamicString";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "backend", kind: "message", T: BackendTranslatedString },
    { no: 2, name: "client", kind: "message", T: ClientTranslatedString },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DynamicString {
    return new DynamicString().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DynamicString {
    return new DynamicString().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DynamicString {
    return new DynamicString().fromJsonString(jsonString, options);
  }

  static equals(a: DynamicString | PlainMessage<DynamicString> | undefined, b: DynamicString | PlainMessage<DynamicString> | undefined): boolean {
    return proto3.util.equals(DynamicString, a, b);
  }
}

/**
 * @generated from message shackle.common.PaymentExplanation
 */
export class PaymentExplanation extends Message<PaymentExplanation> {
  /**
   * @generated from field: repeated shackle.common.PaymentExplanation.ExplanationPoint explanation_point = 1;
   */
  explanationPoint: PaymentExplanation_ExplanationPoint[] = [];

  /**
   * @generated from field: optional string title = 2;
   */
  title?: string;

  constructor(data?: PartialMessage<PaymentExplanation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.PaymentExplanation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "explanation_point", kind: "message", T: PaymentExplanation_ExplanationPoint, repeated: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentExplanation {
    return new PaymentExplanation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentExplanation {
    return new PaymentExplanation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentExplanation {
    return new PaymentExplanation().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentExplanation | PlainMessage<PaymentExplanation> | undefined, b: PaymentExplanation | PlainMessage<PaymentExplanation> | undefined): boolean {
    return proto3.util.equals(PaymentExplanation, a, b);
  }
}

/**
 * @generated from message shackle.common.PaymentExplanation.ExplanationPoint
 */
export class PaymentExplanation_ExplanationPoint extends Message<PaymentExplanation_ExplanationPoint> {
  /**
   * @generated from field: string icon = 1;
   */
  icon = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  constructor(data?: PartialMessage<PaymentExplanation_ExplanationPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "shackle.common.PaymentExplanation.ExplanationPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentExplanation_ExplanationPoint {
    return new PaymentExplanation_ExplanationPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentExplanation_ExplanationPoint {
    return new PaymentExplanation_ExplanationPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentExplanation_ExplanationPoint {
    return new PaymentExplanation_ExplanationPoint().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentExplanation_ExplanationPoint | PlainMessage<PaymentExplanation_ExplanationPoint> | undefined, b: PaymentExplanation_ExplanationPoint | PlainMessage<PaymentExplanation_ExplanationPoint> | undefined): boolean {
    return proto3.util.equals(PaymentExplanation_ExplanationPoint, a, b);
  }
}

