<template>
  <div class="flex flex-col items-center space-y-2 py-8 transition-colors">
    <span class="uppercase text-xs font-bold" :class="theme.textColor">Powered by</span>
    <img
      :src="theme.imageSource"
      :key="theme.imageSource"
      height="50"
      class="max-h-12"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  theme?: 'light' | 'dark'
}>()
const { themeName } = usePoweredByTheme()

if (props.theme) themeName.value = props.theme

const theme = computed(() => {
  return {
    imageSource: themeName.value === 'light' ? '/images/logo-light.svg' : '/images/logo-dark.svg',
    textColor: themeName.value === 'light' ? 'text-white' : 'text-zinc-400'
  }
})
</script>